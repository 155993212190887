import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Layout(props) {
  const navigate = useNavigate();
  useEffect(() => {
    let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
    if (
        appInfo &&
        appInfo["isPayer"] === true &&
        appInfo["isClient"] === false &&
        appInfo["isAdmin"] === false
      )
      {
        if(sessionStorage.getItem("OriginalsurveyLink") !== null && sessionStorage.getItem("OriginalsurveyLink") !== undefined)
        {
            props.surveyStore.increaseNavigationCounter();
            navigate(sessionStorage.getItem("OriginalsurveyLink").split(".com")[1]);
        }
        else
        {
            props.surveyStore.increaseNavigationCounter();
            navigate("/payerstatus");
        }
      }
      else{
        props.surveyStore.increaseNavigationCounter();
        navigate("/currentsurveys");
      }
    
      
    }, []);
  return (<div></div>);
    
}
