import CustomLeftPanel from "../../component/CustomLeftPanel";
import { useState, useEffect } from "react";
import SortableItems from "../../component/SortableItems";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import Stepper from "../../component/Stepper";
import { Button, Select, Space, Card, Modal, message, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FileAddOutlined, CaretRightFilled } from "@ant-design/icons";
import { Upload } from "antd";
import { request } from "../../App";

const { Option } = Select;

const QUESTYPE = {
  1: "Open-ended",
  2: "Rating",
  3: "Single-choice + open-ended",
  4: "Ranking",
  5: "Single-choice",
  6: "Multi-select",
  7: "Multi-select + open-ended",
};

const getQuestionData = async (clientId, surveyId) => {
  let ResultArray = [];
  //API to get questions of a particular survey
  await request
    .get(
      APP_CONSTANTS.API_URL +
        "/EachSurveyInfo?ClientId=" +
        clientId +
        "&SurveyId=" +
        surveyId
    )
    .then((res) => {
      // console.log(res);
      const result = res.data.result;
      ResultArray = result;
      // console.log("survey name", result[0]["surveyName"]);
      // console.log("Result: from api", result);
    })
    .catch((err) => {
      // console.log({ err });
    });
  return ResultArray;
};

function CreateSurveyQuestions({
  QuestionBankStore,
  SurveyStore,
  CurrentSurveyStore,
}) {
  const [leftPanelArray_New, setLeftPanelArray] = useState([]);
  const [filteredLeftPanelArray, setFilteredLeftPanelArray] = useState([]);
  const [searchLeftPanelArray, setSearchLeftPanelArray] = useState([]);
  const [isModalVisibleCancel, setIsModalVisibleCancel] = useState(false);
  const [isModalVisiblePrevious, setIsModalVisiblePrevious] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrafted, setIsDrafted] = useState(false);
  const [defaultDocumentList, setDefaultDocumentList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const navigate = useNavigate();
  const maxQuestionsPerSurvey = 10;
  const maxOpenTextQuestionsPerSurvey = 3;
  let navigateToNext = false;

  useEffect(() => {
    //populate the uploaded document list
    let temp = [];
    for (let i = 0; i < defaultDocumentList.length; i++) {
      temp.push(defaultDocumentList[i]["name"]);
    }
    setDocumentList([...temp]);
  }, [defaultDocumentList]);

  const GetLeftPanelArray = async () => {
    let ResultArray = [];
    //API to get question bank data
    await request
      .get(APP_CONSTANTS.API_URL + "/QuestionBank")
      .then((res) => {
        const result = res.data.result;
        ResultArray = result;
        // console.log("api", result);
      })
      .catch((err) => {
        // console.log({ err });
      });
    return ResultArray;
  };

  const fcn_ProcessJSONFromAPI = (dummyJSON) => {
    //function to process the question bank data to convert in desired format
    let ResultArray = [];

    if (dummyJSON != null) {
      let DistinctQuesTitleArray = [
        ...new Set(dummyJSON.map((data) => data.quesTitle)),
      ];
      var counter = 1;
      DistinctQuesTitleArray.forEach((input) => {
        let ResultJSOn = {
          id: null,
          quesTitle: null,
          quesSet: [
            {
              quesID: null,
              quesText: null,
              quesType: null,
              quesTypeName: null,
              questionCategory: null,
              responseOptions: [],
              customResponses: [],
              isUserCreated: false,
              responseLimit: 0,
              quesTitle: null,
            },
          ],
        };
        let Array = dummyJSON.filter((temp) => temp.quesTitle == input);
        let quesSet_temp = [];
        Array.forEach((item) => {
          quesSet_temp.push({
            quesID: item.quesID,
            quesText: item.quesText,
            responseOptions: item.responseOptions,
            quesType: item.quesTypeId,
            quesTypeName: item.quesTypeName,
            quesCategory: item.quesCategory,
            customResponses: item.customResponses,
            isUserCreated: item.isUserCreated,
            responseLimit: item.responseLimit,
            quesTitle: item.quesTitle,
          });
        });
        ResultJSOn.id = counter;
        ResultJSOn.quesTitle = input;
        ResultJSOn.quesSet = quesSet_temp;
        ResultArray.push(ResultJSOn);
        counter = counter + 1;
      });
    }
    return ResultArray;
  };

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  useEffect(() => {
    // to prepopulate the existing survey related question on screen
    window.scrollTo(0, 0);
    let tempItems = [];
    // console.log("from store", SurveyStore.returnData());
    if (
      SurveyStore.returnData()[0]["quesText"] != "" &&
      SurveyStore.returnData()[0]["quesText"] != null
    ) {
      const datafromStore = SurveyStore.returnData();
      // remove optional questions from the survey data
      for (let i = 0; i < datafromStore.length; i++) {
        if (datafromStore[i]["quesTitle"] !== "Optional") {
          tempItems.push({
            id: datafromStore[i]["quesID"],
            mainTitle: datafromStore[i]["quesText"],
            quesType: datafromStore[i]["quesType"],
            data: datafromStore[i]["responseOptions"],
            quesTypeName: QUESTYPE[datafromStore[i]["quesType"]],
            customResponses: datafromStore[i]["customResponses"],
            isUserCreated: datafromStore[i]["isUserCreated"],
            responseLimit: datafromStore[i]["responseLimit"],
            quesTitle: datafromStore[i]["quesTitle"],
            is_highlight: datafromStore[i]["is_highlight"],
          });
        }
      }
      setItems([...tempItems]);
    }
    //update defaultDocumentList to be displayed on screen
    const datafromStore = SurveyStore.returnData();
    if (datafromStore !== null && datafromStore !== undefined) {
      let links = datafromStore[0]["docs_link"];
      if (links !== undefined && links !== null) {
        let temp = [];
        for (let i = 0; i < links.length; i++) {
          temp.push({
            uid: i + 1,
            name: links[i],
            status: "done",
          });
        }
        setDefaultDocumentList([...temp]);
      }
    }
  }, []);

  //function which adds a question card on the screen
  const addCard = (
    ques,
    quesType,
    options,
    id,
    quesTypeName,
    customResponses,
    isUserCreated,
    responseLimit,
    quesTitle,
    is_highlight
  ) => {
    //check max question limit for this survey
    if (items.length >= maxQuestionsPerSurvey) {
      message.error(
        `You can add upto ${maxQuestionsPerSurvey} questions per survey! Please edit or remove other questions to continue.`
      );
      return;
    }
    //check max open text question limit for this survey
    let openTextQuestionCount = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i]["quesType"] === 1) openTextQuestionCount++;
    }
    if (
      openTextQuestionCount >= maxOpenTextQuestionsPerSurvey &&
      quesType === 1
    ) {
      message.error(
        `You can add upto ${maxOpenTextQuestionsPerSurvey} open-text questions per survey!`
      );
      return;
    }
    if (!isUserCreated) {
      //check whether same question is added or not, only for questions from question bank
      let check = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i]["mainTitle"] === ques) {
          // console.log("Found Duplicate Question");
          check = false;
          break;
        }
      }
      if (check) {
        items.push({
          id: id,
          mainTitle: ques,
          quesType: quesType,
          data: options,
          quesTypeName: quesTypeName,
          customResponses: customResponses,
          isUserCreated: isUserCreated,
          responseLimit: responseLimit,
          quesTitle: quesTitle,
          is_highlight: is_highlight,
        });
        setItems([...items]);
      } else {
        message.error("Question already exists", [1]);
      }
    } else {
      items.push({
        id: id,
        mainTitle: ques,
        quesType: quesType,
        data: options,
        quesTypeName: quesTypeName,
        customResponses: customResponses,
        isUserCreated: isUserCreated,
        responseLimit: responseLimit,
        quesTitle: quesTitle,
        is_highlight: is_highlight,
      });
      setItems([...items]);
    }
  };

  useEffect(() => {
    // to populate the question bank
    GetLeftPanelArray()
      .then((res) => fcn_ProcessJSONFromAPI(res))
      .then((output) => {
        setLeftPanelArray(output);
        // remove optional questions from the question bank
        let copy = [...output];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i]["quesTitle"] === "Optional") {
            copy.splice(i, 1);
            break;
          }
        }
        let customQuesSet = {};
        for (let i = 0; i < copy.length; i++) {
          if (copy[i]["quesTitle"] === "Custom") {
            customQuesSet = copy.splice(i, 1);
            break;
          }
        }
        if (
          customQuesSet[0] !== undefined &&
          customQuesSet[0] !== null &&
          customQuesSet.length > 0
        ) {
          copy.push(customQuesSet[0]);
        }
        // copy.push(customQuesSet[0]);
        // console.log("copy", copy);
        setSearchLeftPanelArray(copy);
        setFilteredLeftPanelArray(copy);
        setIsLoading(false);
        QuestionBankStore.addQuestionBank(copy);
      });
  }, []);

  function filterQuesTextHandler(value) {
    //filter on question bank
    //for general question category is blank (" ")
    const filterText = value.target.value;
    if (filterText === "None") {
      setFilteredLeftPanelArray(QuestionBankStore.getQuestionBank()[0]);
      return;
    }
    let tempQuesBank = [];
    for (let i = 0; i < searchLeftPanelArray.length; i++) {
      let tempQuesSet = searchLeftPanelArray[i]["quesSet"];
      let tempQuesSetObject = {
        quesSet: [],
        quesTitle: "",
      };
      for (let j = 0; j < tempQuesSet.length; j++) {
        if (
          tempQuesSet[j]["quesCategory"] === filterText ||
          tempQuesSet[j]["quesCategory"] === " "
        ) {
          tempQuesSetObject["quesTitle"] = searchLeftPanelArray[i]["quesTitle"];
          tempQuesSetObject["quesSet"].push(tempQuesSet[j]);
        }
      }
      if (tempQuesSetObject["quesSet"].length !== 0) {
        tempQuesBank.push(tempQuesSetObject);
      }
    }
    setFilteredLeftPanelArray([...tempQuesBank]);
  }

  function searchQuesBank(value) {
    //search in question bank
    const searchText = value.target.value.toLowerCase();
    if (searchText === "") {
      setFilteredLeftPanelArray(QuestionBankStore.getQuestionBank()[0]);
      return;
    }
    let tempQuesBank = [];
    for (let i = 0; i < searchLeftPanelArray.length; i++) {
      let tempQuesSet = searchLeftPanelArray[i]["quesSet"];
      let tempQuesSetObject = {
        quesSet: [],
        quesTitle: "",
      };
      for (let j = 0; j < tempQuesSet.length; j++) {
        if (tempQuesSet[j]["quesText"].toLowerCase().search(searchText) >= 0) {
          tempQuesSetObject["quesTitle"] = searchLeftPanelArray[i]["quesTitle"];
          tempQuesSetObject["quesSet"].push(tempQuesSet[j]);
        }
      }
      if (tempQuesSetObject["quesSet"].length !== 0) {
        tempQuesBank.push(tempQuesSetObject);
      }
    }
    setFilteredLeftPanelArray([...tempQuesBank]);
  }

  function ReviewSurvey() {
    //navigate to review survey screen
    navigateToNext = true;
    SaveDraftHandler();
  }

  const showModalCancel = () => {
    setIsModalVisibleCancel(true);
  };
  const showModalPrevious = () => {
    setIsModalVisiblePrevious(true);
  };

  const handleOk = () => {
    setIsModalVisibleCancel(false);
    setIsLoading(true);
    // get survey store data and delete the store
    const storeData = SurveyStore.returnData();
    SurveyStore.deleteStoreData();
    // if (!SurveyStore.getIsEdited()) {
    //   if (isDrafted) {
    //     // API to delete survey from DB
    //     axios
    //       .delete(
    //         APP_CONSTANTS.API_URL +
    //           "/DeleteSurvey?ClientId=" +
    //           storeData[0]["clientID"] +
    //           "&SurveyId=" +
    //           storeData[0]["surveyID"]
    //       )
    //       .then((res) => {
    //         if (res["status"] === 200) {
    //           // console.log(
    //             "Deleted success >> Survey ID:",
    //             storeData[0]["surveyID"]
    //           );
    //           // console.log("survey id", storeData[0]["surveyID"]);
    //           CurrentSurveyStore.deleteSurvey(storeData[0]["surveyID"]);
    //         } else {
    //           // console.log("error deleting survey");
    //         }
    //       })
    //       .catch((error) => {
    //         message.error("Error");
    //         // console.log(error);
    //         setIsLoading(false);
    //       })
    //       .then((res) => {
    //         setIsLoading(false);
    //         navigate("/currentsurveys");
    //       });
    //   } else {
    //     setIsLoading(false);
    //     navigate("/currentsurveys");
    //   }
    //   setIsDrafted(false);
    // } else {
    navigate("/currentsurveys");
    setIsLoading(false);
  };

  const handleOkPrevious = () => {
    //navigate to previous screen
    //mark the survey as edited
    setIsLoading(true);
    SurveyStore.setIsEdited(true);
    setIsModalVisiblePrevious(false);
    setIsLoading(false);
    navigate("/createsurvey");
  };

  const handleCancel = () => {
    setIsModalVisibleCancel(false);
    setIsModalVisiblePrevious(false);
  };

  function CustomQuestionHandler(value) {
    //add a custom question to items (isUserCreated=true)
    //value is question type
    if (value === 1) {
      addCard("", value, {}, 0, QUESTYPE[value], [], true, 0, "Custom");
      return;
    }
    if (value === 2) {
      addCard(
        "",
        value,
        { 1: "1", 2: "2", 3: "3" },
        0,
        QUESTYPE[value],
        ["3"],
        true,
        0,
        "Custom"
      );
      return;
    }
    if (value === 4) {
      addCard(
        "",
        value,
        { 1: "", 2: "" },
        0,
        QUESTYPE[value],
        ["Response One", "Response Two"],
        true,
        2,
        "Custom"
      );
      return;
    }

    addCard(
      "",
      value,
      { 1: "", 2: "" },
      0,
      QUESTYPE[value],
      ["Response One", "Response Two"],
      true,
      0,
      "Custom"
    );
  }

  function addToDefaultDocumentList(links) {
    // add the documents to defaultDocumentList to be displayed on the screen
    if (links !== undefined && links !== null) {
      let temp = [];
      for (let i = 0; i < links.length; i++) {
        temp.push({
          uid: i + 1,
          name: links[i],
          status: "done",
        });
      }
      setDefaultDocumentList([...temp]);
    }
  }

  function SaveDraftHandler() {
    //save the added questions to DB
    let lastSavedStep = 2;
    if (navigateToNext) {
      // if navigating to review survey
      lastSavedStep = 3;
    }
    // add data to store as well as DB
    const itemsToDB = [...items];

    //check if additional comments question is already added or not
    let additionalCheck = true;
    for (let i = 0; i < itemsToDB.length; i++) {
      if (itemsToDB[i]["quesTitle"] === "Optional") additionalCheck = false;
    }
    if (additionalCheck) {
      // console.log("Checking left panel array", leftPanelArray_New);
      for (let i = 0; i < leftPanelArray_New.length; i++) {
        if (leftPanelArray_New[i]["quesTitle"] === "Optional") {
          // console.log("pushing optional ques");
          for (let j = 0; j < leftPanelArray_New[i]["quesSet"].length; j++) {
            itemsToDB.push({
              id: leftPanelArray_New[i]["quesSet"][j]["quesID"],
              mainTitle: leftPanelArray_New[i]["quesSet"][j]["quesText"],
              quesType: leftPanelArray_New[i]["quesSet"][j]["quesType"],
              data: leftPanelArray_New[i]["quesSet"][j]["responseOptions"],
              quesTypeName: leftPanelArray_New[i]["quesSet"][j]["quesTypeName"],
              customResponses:
                leftPanelArray_New[i]["quesSet"][j]["customResponses"],
              isUserCreated:
                leftPanelArray_New[i]["quesSet"][j]["isUserCreated"],
              quesTitle: leftPanelArray_New[i]["quesTitle"],
              responseLimit:
                leftPanelArray_New[i]["quesSet"][j]["responseLimit"],
            });
          }
        }
      }
    }

    // check for duplicate or blank question/responses
    for (let i = 0; i < itemsToDB.length; i++) {
      if (itemsToDB[i]["mainTitle"] === "") {
        message.error("Some Questions are blank. Please Check!", [1.2]);
        return;
      } else if (
        itemsToDB[i]["data"] !== undefined &&
        itemsToDB[i]["data"] !== null
      ) {
        let dataKeys = Object.keys(itemsToDB[i]["data"]);
        for (let j = 0; j < dataKeys.length; j++)
          if (itemsToDB[i]["data"][dataKeys[j]] === "") {
            message.error("Some Responses are blank. Please Check!", [1.2]);
            return;
          }
      }
    }

    //add items to store and db
    setIsLoading(true);
    SurveyStore.addQuestionFromSurveyQuestions(itemsToDB);
    SurveyStore.addDocsLink(documentList);
    addToDefaultDocumentList(documentList);
    const storeData = SurveyStore.returnData();
    setIsDrafted(true);
    message.loading("Saving!", [1]);
    //API to save the survey details
    //returns a surveyID alloted to this survey
    request
      .post(
        APP_CONSTANTS.API_URL +
          "/CreateNewSurvey?ClientId=" +
          storeData[0]["clientID"] +
          "&lastSavedStep=" +
          lastSavedStep,
        storeData
      )
      .then((response) => {
        // console.log("resonse from api", response);
        //update the survey id in storeData
        for (let i = 0; i < storeData.length; i++) {
          storeData[i]["surveyID"] = response.data.result[0];
          let customQuestions = Object.keys(response.data.result[1]);
          for (let j = 0; j < customQuestions.length; j++) {
            let temp = [...searchLeftPanelArray];

            if (storeData[i]["quesText"] === customQuestions[j]) {
              itemsToDB[i]["id"] = response.data.result[1][customQuestions[j]];
              itemsToDB[i]["isUserCreated"] = false;
              storeData[i]["quesID"] =
                response.data.result[1][customQuestions[j]];
              storeData[i]["isUserCreated"] = false;

            }
          }
        }
        CurrentSurveyStore.addToCurrentSurveysFromSurveyFields(storeData[0]);
        SurveyStore.deleteStoreData();

        SurveyStore.addSurveyFieldsData(storeData[0]);
        SurveyStore.addQuestionData(storeData);
        console.log(
          "StoreData after updating surveyID",
          SurveyStore.returnData()
        );
        setIsLoading(false);
        if (response.data.status === 200) {
          // console.log("Data sent to DB successfully", response);
          message.success("Saved!");
          setItems([...itemsToDB]);
          if (navigateToNext) {
            SurveyStore.increaseNavigationCounter();
            navigate("/reviewsurvey");
          }
        } else message.error("Error!");
      })
      .catch((err) => {
        setIsLoading(false);
        message.error("Error!");
        // console.log(err);
      });
  }

  //define props for upload component
  const props = {
    action:
      APP_CONSTANTS.API_URL +
      "/Home/UploadToS3?filePath=" +
      SurveyStore.returnData()[0]["clientID"] +
      "/" +
      SurveyStore.returnData()[0]["surveyID"],
    withCredentials: true,
    beforeUpload: (file) => {
      //check for duplicate file
      for (var i = 0; i < documentList.length; i++) {
        if (documentList[i] === file.name) {
          message.error("File already uploaded!");
          return Upload.LIST_IGNORE;
        }
      }
      if (file.size > 16384) {
        message.error("File exceeds the 16MB size limit");
        return Upload.LIST_IGNORE;
      }

      //check for invalid file name and type
      if (String(file.name).includes(" ")) {
        // console.log("space found in filename");
        message.error("File Name should not have (space) character!");
        return Upload.LIST_IGNORE;
      } else {
        //check if file type is pdf/xls/txt/doc
        const isPDF = file.type === "application/pdf";
        const isXls =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isTxt = file.type === "text/plain";
        const isDoc =
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        // console.log("filetype", file.type);
        if (!isXls && !isDoc && !isPDF && !isTxt) {
          message.error(
            `${file.name} is not a valid file type. Accepted Types (.pdf | .doc | .txt | .xlsx)`
          );
        }
        return isXls || isDoc || isPDF || isTxt || Upload.LIST_IGNORE;
      }
    },
    defaultFileList: defaultDocumentList,
    onRemove(file) {
      //remove document from store
      // console.log("file removed", file);
      let temp = [...documentList];
      for (var i = 0; i < temp.length; i++) {
        if (temp[i] === file.name) {
          temp.splice(i, 1);
        }
      }
      // console.log("documentList", temp);
      setDocumentList([...temp]);
    },
    onChange(info) {
      //update document list when file is uploaded
      if (info.file.status !== "uploading") {
        // console.log("filelist", info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} Uploaded`);
        let temp = [];
        info.fileList.forEach((item) => {
          temp.push(item["name"]);
        });
        setDocumentList([...temp]);
      } else if (info.file.status === "error") {
        message.error("Error!");
        for (let i = 0; i < info.fileList.length; i++) {
          if (info.fileList[i]["name"] === info.file.name) {
            info.fileList.splice(i, 1);
          }
        }
        let temp = [];
        info.fileList.forEach((item) => {
          temp.push(item["name"]);
        });
        setDocumentList([...temp]);
        setDefaultDocumentList(documentList);
      }
    },
  };

  return (
    <div>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "87.5vh" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : (
        <div className="mb-2">
          <Stepper current={1} />
          <div className="px-24 mt-6">
            <div className="flex flex-wrap mt-2">
              <div className="md:w-2/5 pr-4 pl-4">
                <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300">
                  <div className="flex-auto p-6 ">
                    <div>
                      <p className="font-semibold text-lg mb-1">
                        Question Bank
                      </p>
                      <p className="fw-bold text-sm">
                        Following set of questions are usually a part of the
                        survey you are trying to build & can serve as a template
                        for your actual survey questions.
                        <br />
                        Click on '+' icon to add these questions to your survey
                      </p>
                    </div>
                    <div className="relative flex items-stretch w-full mb-2 ">
                      <input
                        type="text"
                        className="block appearance-none w-full mb-1 bg-white text-gray-900 border border-gray-200 py-1 px-2 text-sm leading-normal rounded"
                        placeholder="Search Here"
                        onChange={searchQuesBank}
                      />
                    </div>
                    <div className="flex">
                      <div className="mt-1 w-1/4">
                        <p>Select Category</p>
                      </div>
                      <div style={{ height: "2rem" }} className="w-3/4 pl-4">
                        <select
                          onChange={filterQuesTextHandler}
                          className="block appearance-none w-full py-1 px-2 mb-1 leading-normal bg-white text-gray-800 border border-gray-200 rounded"
                        >
                          <option value="None">Select All</option>
                          <option value="Current and Future Landscape">
                            Current and Future Landscape
                          </option>
                          <option value="Reaction to Product Profiles">
                            Reaction to Product Profiles
                          </option>
                          <option value="Pricing and Access Potential">
                            Pricing and Access Potential
                          </option>
                          <option value="Value and Evidence">
                            Value and Evidence
                          </option>
                          <option value="Custom">Custom</option>
                        </select>
                      </div>
                    </div>
                    <hr className="m-0" />

                    <div className="scrollable">
                      {filteredLeftPanelArray.length > 0
                        ? filteredLeftPanelArray.map((item, index) => {
                            return (
                              <CustomLeftPanel
                                key={index}
                                title={item.quesTitle}
                                quesSet={item.quesSet}
                                id={item.id}
                                addCard={addCard}
                              />
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="">
                    <Upload {...props}>
                      <Tooltip
                        arrowPointAtCenter={true}
                        overlayStyle={{ minWidth: "18rem" }}
                        overlayInnerStyle={{ fontSize: "0.78rem" }}
                        title={
                          <div className="">
                            <p className="mb-0 flex items-center ml-1">
                              <CaretRightFilled
                                style={{ fontSize: "0.7rem" }}
                              />{" "}
                              &nbsp; File Name should not have (space)
                              character!
                            </p>
                            <p className="mb-0 flex items-center ml-1">
                              <CaretRightFilled
                                style={{ fontSize: "0.7rem" }}
                              />
                              &nbsp; Accepted File Types (.pdf | .doc | .txt |
                              .xlsx)
                            </p>
                            <div>
                              <p className="mb-0 flex items-center ml-1">
                                <CaretRightFilled
                                  style={{ fontSize: "0.7rem" }}
                                />
                                &nbsp; Please ensure the file name is compliant
                                with
                              </p>
                              <p className="mb-0 flex items-center ml-5 pl-0.5">
                                blinded market research protocols
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <Button className="mt-2 mr-4" type="primary">
                          Upload Documents
                        </Button>
                      </Tooltip>
                    </Upload>
                  </div>
                  <div className="flex">
                    <Select
                      id="customQuesSelector"
                      style={{
                        width: "13rem",
                        height: "2.2rem",
                        marginTop: "0.5rem",
                      }}
                      onSelect={CustomQuestionHandler}
                      placeholder="+ Custom Question"
                      className="mt-2"
                    >
                      <Option value={1}>Open-Ended</Option>
                      <Option value={2}>Rating</Option>
                      <Option value={4}>Ranking</Option>
                      <Option value={5}>Single-Choice</Option>
                      <Option value={3}>Single-Choice + Open-Ended</Option>
                      <Option value={6}>Multi-Select</Option>
                      <Option value={7}>Multi-Select + Open-Ended</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="md:w-3/5 pr-4 pl-4">
                <div className="text-center fw-bold p-2 ms-2">
                  <h3 className="fw-bold text-gray text-xl">
                    Questions Added: {items.length}
                  </h3>
                </div>
                <div className="flex flex-wrap ">
                  <div className="flex">
                    <div className="ml-2 text-sm text-gray-800">
                      <b className="text-black">Description:&nbsp;</b>
                      {SurveyStore.returnData()[0]["surveyDescription"]}
                    </div>
                  </div>
                </div>
                {items.length === 0 ? (
                  <div className="w-full text-gray text-center mt-4 mb-4 border-gray-300 border-2 h-44">
                    <div className="mt-4">
                      <FileAddOutlined
                        style={{ fontSize: "5rem", color: "darkslategray" }}
                      />
                    </div>
                    <br />
                    Add a question from question bank or create a custom
                    question
                  </div>
                ) : (
                  <SortableItems items={items} setItems={setItems} />
                )}
              </div>
            </div>
          </div>
          <div id="footerOne">
            <Card size="small">
              <Space>
                <Tooltip
                  title={`Proceeding to "Review Survey" will automatically save your current progress`}
                >
                  <Button type="primary" onClick={ReviewSurvey}>
                    Review Survey
                  </Button>
                </Tooltip>
                <Button onClick={SaveDraftHandler}>Save as Draft</Button>
                <Button onClick={showModalPrevious}>Previous</Button>
                <Button type="danger" onClick={showModalCancel}>
                  Cancel
                </Button>
              </Space>
            </Card>
          </div>
        </div>
      )}
      <Modal
        title="Cancel Survey"
        visible={isModalVisibleCancel}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {!SurveyStore.getIsEdited() ? (
          <p>Do you want to delete this survey and all its related data?</p>
        ) : (
          <p>Are you sure? Changes made won't be applied!</p>
        )} */}
        <p>Are you sure? Changes made won't be applied!</p>
      </Modal>
      <Modal
        title="Go to Previous Screen"
        visible={isModalVisiblePrevious}
        onOk={handleOkPrevious}
        onCancel={handleCancel}
      >
        <p>Make sure you have saved you data before going back!</p>
      </Modal>
    </div>
  );
}

export default CreateSurveyQuestions;
