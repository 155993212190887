import { ResponsiveBar } from "@nivo/bar";
import { Card, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { BoxLegendSvg } from "@nivo/legends";

const dataFormat = [
  // this should be the format of renderData
  // array of label and indices
  {
    x: "AD", // your x-axis index lable
    value: 5, // value for label AD
    // value1:10, //add more values to make a stacked graph
  },
  {
    x: "AE",
    value: 3,
  },
];

// Our custom tick render component
const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
  const MAX_LINE_LENGTH = 16;
  const MAX_LINES = 2;
  const LENGTH_OF_ELLIPSIS = 3;
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
  const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    "gm"
  );
  const splitValues = value
    .replace(trimWordsOverLength, "$1...")
    .match(groupWordsByLength)
    .slice(0, 2)
    .map((val, i) => {
      if (value.length > 17)
        return (
          <tspan
            key={val}
            dy={2.5 * i + 10}
            x={-10}
            style={{
              fill: "#454250",
              fontSize: "0.675rem",
              fontWeight: 600,
            }}
          >
            {val}
          </tspan>
        );
      else
        return (
          <tspan
            key={val}
            dy={2.5 * i + 10}
            x={-10}
            style={{
              fill: "#454250",
              fontSize: "0.675rem",
              fontWeight: 600,
            }}
          >
            {val}
          </tspan>
        );
    });

  return (
    <Tooltip title={value}>
      <g transform={`translate(${x + 10},${y + 2})`}>
        <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
          {splitValues}
        </text>
      </g>
    </Tooltip>
  );
};

// const baseColors = [
//   ["#CAF2ED", "#82DED3", "#20BDAA", "#168477"], //verbiter
//   ["#FEF0E2", "#FEE1C6", "#FDCB9C", "#FDB571"], //orpiment
//   ["#7acfe5", "#5fb5db", "#3f9abf", "#1680a4"], //blue
//   ["#ffd090", "#ffc26c", "#ffb446", "#fea610"], //orange
//   ["#B3E8E5", "#82DBD8", "#3BACB6", "#2F8F9D"], //teal
//   ["#E9EFC0", "#B4E197", "#83BD75", "#4E944F"], //green
//   ["#fcbad4", "#f796bb", "#eb5b93", "#e34381"], //pink
//   ["#E9D5DA", "#827397", "#4D4C7D", "#44446F"], //navy blue
//   ["#e3badd", "#d18cc6", "#bf5eaf", "#ad3099"], //purple
//   ["#ebe0e3", "#d7c0c7", "#c4a1ab", "#a77281"], //beige
// ];

//baseColors reversed (dark--->light)
const baseColors = [
  ["#20BDAA", "#57D1C2", "#99E4DC", "#CAF2ED"], //verbiter
  ["#FDB571", "#FDCB9C", "#FEE1C6", "#FEF0E2"], //orpiment
  ["#DB6C03", "#E2862F", "#EAA25F", "#EDB078"], //orpiment - dark
  ["#1680a4", "#3f9abf", "#5fb5db", "#7acfe5"], //blue
  ["#4E944F", "#83BD75", "#B4E197", "#E9EFC0"], //green
  ["#e34381", "#eb5b93", "#f796bb", "#fcbad4"], //pink
  ["#44446F", "#4D4C7D", "#827397", "#E9D5DA"], //navy blue
  ["#ad3099", "#bf5eaf", "#d18cc6", "#e3badd"], //purple
  ["#a77281", "#c4a1ab", "#d7c0c7", "#ebe0e3"], //beige
  ["#2F8F9D", "#3BACB6", "#82DBD8", "#B3E8E5"], //teal
  ["#fea610", "#ffb446", "#ffc26c", "#ffd090"], //orange
];
function BarChart({ data, split, type }) {
  const firstColors = [
    "#20BDAA",
    "#FDB571",
    "#DB6C03",
    "#1680a4",
    "#4E944F",
    "#e34381",
    "#44446F",
    "#ad3099",
    "#a77281",
    "#2F8F9D",
    "#fea610",
  ];
  const [renderData, setRenderData] = useState([]);
  const [keys, setKeys] = useState(["value"]);
  const [colors, setColors] = useState(firstColors);
  const [tickValues, setTickValues] = useState(1);

  function compare(a, b) {
    if (a.field < b.field) {
      return -1;
    }
    if (a.field > b.field) {
      return 1;
    }
    return 0;
  }

  //define a groupby function which can be used to group data based on the split selected.
  Array.prototype.groupBy = function (field) {
    let groupedArr = [];
    this.forEach(function (e) {
      //look for an existent group
      let group = groupedArr.find((g) => g["field"] === e[field]);
      if (group == undefined) {
        //add new group if it doesn't exist
        group = { field: e[field], groupList: [] };
        groupedArr.push(group);
      }

      //add the element to the group
      group.groupList.push(e);
    });
    groupedArr = groupedArr.sort(compare);

    // if split type is payer type or payer role, we need to apply split according to each country
    // for eg- country - payerType1, country-payerType2
    if (field === "type") {
      let copy = [];
      for (let i = 0; i < groupedArr.length; i++) {
        let tempField = groupedArr[i]["field"];
        let countries = [];
        //get all countries for this payer type
        groupedArr[i]["groupList"].map((item, j) => {
          countries.push(item["country"]);
        });
        let uniqueCountries = countries.filter((c, index) => {
          return countries.indexOf(c) === index;
        });

        for (let j = 0; j < uniqueCountries.length; j++) {
          let tempCopyObject = {
            field: uniqueCountries[j] + " - " + tempField, //country-payerType
            groupList: [], //array containing original data for selected country and payer type
          };

          for (let k = 0; k < groupedArr[i]["groupList"].length; k++) {
            if (
              groupedArr[i]["groupList"][k]["country"] === uniqueCountries[j] &&
              groupedArr[i]["groupList"][k]["type"] === tempField
            ) {
              //if country and payer type matches
              tempCopyObject["groupList"].push(groupedArr[i]["groupList"][k]);
            }
          }
          if (tempCopyObject["groupList"].length !== 0)
            copy.push(tempCopyObject);
        }
      }
      copy = copy.sort(compare);
      let tempColors = Array(copy.length).fill("");
      let countries = [];
      for (let i = 0; i < copy.length; i++) {
        countries.push(copy[i]["field"].split(" - ")[0]);
      }
      let uniqueCountries = countries.filter((c, index) => {
        return countries.indexOf(c) === index;
      });

      for (let i = 0; i < uniqueCountries.length; i++) {
        let k = 0;
        for (let j = 0; j < copy.length; j++) {
          if (copy[j]["field"].split(" - ")[0] === uniqueCountries[i]) {
            tempColors[j] =
              baseColors[i % baseColors.length][
                k++ % baseColors[i % baseColors.length].length
              ];
          }
        }
      }
      setColors(tempColors);

      return copy;
    } else if (field === "role") {
      let copy = [];
      for (let i = 0; i < groupedArr.length; i++) {
        let tempField = groupedArr[i]["field"];
        let countries = [];
        //get all countries for this payer role
        groupedArr[i]["groupList"].map((item, j) => {
          countries.push(item["country"]);
        });
        let uniqueCountries = countries.filter((c, index) => {
          return countries.indexOf(c) === index;
        });

        for (let j = 0; j < uniqueCountries.length; j++) {
          let tempCopyObject = {
            field: uniqueCountries[j] + " - " + tempField, //country-payerRole
            groupList: [], //array containing original data for selected country and payer role
          };

          for (let k = 0; k < groupedArr[i]["groupList"].length; k++) {
            if (
              groupedArr[i]["groupList"][k]["country"] === uniqueCountries[j] &&
              groupedArr[i]["groupList"][k]["role"] === tempField
            ) {
              //if country and payer role matches
              tempCopyObject["groupList"].push(groupedArr[i]["groupList"][k]);
            }
          }
          if (tempCopyObject["groupList"].length !== 0)
            copy.push(tempCopyObject);
        }
      }
      copy = copy.sort(compare);
      let tempColors = Array(copy.length).fill("");
      let countries = [];
      for (let i = 0; i < copy.length; i++) {
        countries.push(copy[i]["field"].split(" - ")[0]);
      }
      let uniqueCountries = countries.filter((c, index) => {
        return countries.indexOf(c) === index;
      });
      for (let i = 0; i < uniqueCountries.length; i++) {
        let k = 0;
        for (let j = 0; j < copy.length; j++) {
          if (copy[j]["field"].split(" - ")[0] === uniqueCountries[i]) {
            tempColors[j] =
              baseColors[i % baseColors.length][
                k++ % baseColors[i % baseColors.length].length
              ];
          }
        }
      }
      setColors(tempColors);
      return copy;
    }
    return groupedArr;
  };

  //determine the type of question and render the bar graph accordingly
  //split determines which split is applied, i.e, by country/ payer type/ payer role
  useEffect(() => {
    if (type === "rating") {
      setColors(firstColors);
      if (split === "none") {
        setKeys(["Overall Ratings"]);
        let temp = [];
        let xAxisValues = Object.keys(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let count = 0;
          for (let j = 0; j < data.length; j++) {
            if (data[j]["response"][0] === xAxisValues[i]) count++;
          }
          temp.push({
            x: xAxisValues[i],
            "Overall Ratings": count,
          });
        }
        setRenderData([...temp]);
        let maxTickValue = 1;
        for (let i = 0; i < temp.length; i++) {
          let tempKeys = Object.keys(temp[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(temp[i][tempKeys[j]])) {
              tickValue += temp[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
      } else {
        let splitData = data.groupBy(split);
        let tempKeys = [];
        for (let k = 0; k < splitData.length; k++) {
          tempKeys.push(splitData[k]["field"]);
        }
        let tempRenderData = [];
        let xAxisValues = Object.keys(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let tempRender = {};
          for (let k = 0; k < splitData.length; k++) {
            let count = 0;
            for (let j = 0; j < splitData[k]["groupList"].length; j++) {
              if (
                splitData[k]["groupList"][j]["response"][0] === xAxisValues[i]
              )
                count++;
            }
            tempRender[[splitData[k]["field"]]] = count;
          }
          tempRender["x"] = xAxisValues[i];
          tempRenderData.push(tempRender);
        }
        setRenderData([...tempRenderData]);
        setKeys([...tempKeys]);
        let maxTickValue = 1;
        for (let i = 0; i < tempRenderData.length; i++) {
          let tempKeys = Object.keys(tempRenderData[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(tempRenderData[i][tempKeys[j]])) {
              tickValue += tempRenderData[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
      }
    } else if (type === "single") {
      setColors(firstColors);
      let splitData = data.groupBy(split);
      let tempKeys = [];
      for (let k = 0; k < splitData.length; k++) {
        tempKeys.push(splitData[k]["field"]);
      }
      let tempRenderData = [];
      let xAxisValues = Object.values(data[0]["responseOptions"]);
      for (let i = 0; i < xAxisValues.length; i++) {
        let tempRender = {};
        for (let k = 0; k < splitData.length; k++) {
          let count = 0;
          for (let j = 0; j < splitData[k]["groupList"].length; j++) {
            if (splitData[k]["groupList"][j]["response"][0] === xAxisValues[i])
              count++;
          }
          tempRender[[splitData[k]["field"]]] = count;
        }
        tempRender["x"] = xAxisValues[i];
        tempRenderData.push(tempRender);
      }
      setRenderData([...tempRenderData]);
      let maxTickValue = 1;
      for (let i = 0; i < tempRenderData.length; i++) {
        let tempKeys = Object.keys(tempRenderData[i]);
        let tickValue = 0;
        for (let j = 0; j < tempKeys.length; j++) {
          if (Number.isInteger(tempRenderData[i][tempKeys[j]])) {
            tickValue += tempRenderData[i][tempKeys[j]];
          }
        }
        if (maxTickValue < tickValue) {
          maxTickValue = tickValue;
        }
      }
      setTickValues(maxTickValue);
      setKeys([...tempKeys]);
    } else if (type === "multi") {
      setColors(firstColors);
      if (split === "none") {
        setKeys(["Total Number of Selections"]);
        let temp = [];
        let xAxisValues = Object.values(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let count = 0;
          for (let j = 0; j < data.length; j++) {
            for (let k = 0; k < data[j]["response"].length; k++)
              if (data[j]["response"][k] === xAxisValues[i]) count++;
          }
          temp.push({
            x: xAxisValues[i],
            "Total Number of Selections": count,
          });
        }
        setRenderData([...temp]);
        let maxTickValue = 1;
        for (let i = 0; i < temp.length; i++) {
          let tempKeys = Object.keys(temp[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(temp[i][tempKeys[j]])) {
              tickValue += temp[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
      } else {
        let splitData = data.groupBy(split);
        let tempKeys = [];
        for (let k = 0; k < splitData.length; k++) {
          tempKeys.push(splitData[k]["field"]);
        }
        let tempRenderData = [];
        let xAxisValues = Object.values(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let tempRender = {};
          for (let k = 0; k < splitData.length; k++) {
            let count = 0;
            for (let j = 0; j < splitData[k]["groupList"].length; j++) {
              for (
                let m = 0;
                m < splitData[k]["groupList"][j]["response"].length;
                m++
              )
                if (
                  splitData[k]["groupList"][j]["response"][m] === xAxisValues[i]
                )
                  count++;
            }
            tempRender[[splitData[k]["field"]]] = count;
          }
          tempRender["x"] = xAxisValues[i];
          tempRenderData.push(tempRender);
        }
        setRenderData([...tempRenderData]);
        let maxTickValue = 1;
        for (let i = 0; i < tempRenderData.length; i++) {
          let tempKeys = Object.keys(tempRenderData[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(tempRenderData[i][tempKeys[j]])) {
              tickValue += tempRenderData[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
        setKeys([...tempKeys]);
      }
    } else if (type === "ranking") {
      setColors(firstColors);
      if (split === "none") {
        setKeys(["Overall Rankings"]);
        let temp = [];
        let xAxisValues = Object.values(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let count = 0;
          for (let j = 0; j < data.length; j++) {
            for (let k = 0; k < data[j]["response"].length; k++)
              if (data[j]["response"][k] === xAxisValues[i]) count++;
          }
          temp.push({
            x: xAxisValues[i],
            "Overall Rankings": count,
          });
        }
        setRenderData([...temp]);
        let maxTickValue = 1;
        for (let i = 0; i < temp.length; i++) {
          let tempKeys = Object.keys(temp[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(temp[i][tempKeys[j]])) {
              tickValue += temp[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
      } else {
        let splitData = data.groupBy(split);
        let tempKeys = [];
        for (let k = 0; k < splitData.length; k++) {
          tempKeys.push(splitData[k]["field"]);
        }
        let tempRenderData = [];
        let xAxisValues = Object.values(data[0]["responseOptions"]);
        for (let i = 0; i < xAxisValues.length; i++) {
          let tempRender = {};
          for (let k = 0; k < splitData.length; k++) {
            let count = 0;
            for (let j = 0; j < splitData[k]["groupList"].length; j++) {
              for (
                let m = 0;
                m < splitData[k]["groupList"][j]["response"].length;
                m++
              )
                if (
                  splitData[k]["groupList"][j]["response"][m] === xAxisValues[i]
                )
                  count++;
            }
            tempRender[[splitData[k]["field"]]] = count;
          }
          tempRender["x"] = xAxisValues[i];
          tempRenderData.push(tempRender);
        }
        setRenderData([...tempRenderData]);
        setKeys([...tempKeys]);
        let maxTickValue = 1;
        for (let i = 0; i < tempRenderData.length; i++) {
          let tempKeys = Object.keys(tempRenderData[i]);
          let tickValue = 0;
          for (let j = 0; j < tempKeys.length; j++) {
            if (Number.isInteger(tempRenderData[i][tempKeys[j]])) {
              tickValue += tempRenderData[i][tempKeys[j]];
            }
          }
          if (maxTickValue < tickValue) {
            maxTickValue = tickValue;
          }
        }
        setTickValues(maxTickValue);
      }
    }
  }, [split]);

  //our custom series of colors
  const custom = [
    "#7acfe5", // blue - 1
    "#5fb5db", // blue - 2
    "#3f9abf", // blue -3
    "#1680a4", // blue - 4
    "#ffd090", // orange - 1
    "#ffc26c", // orange - 2
    "#ffb446", // orange - 3
    "#fea610", // orange - 4
  ];

  //theme for axis
  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "teal",
          strokeWidth: 1,
        },
      },
    },
  };

  return (
    <div
      className="px-4 pt-4 mb-0 border-2 rounded-md"
      style={{ height: "24rem", width: "100%", borderColor: "#0F4E7C" }}
    >
      <div
        className="flex justify-center"
        style={{ height: "88%", width: "100%", borderColor: "#0F4E7C" }}
      >
        <ResponsiveBar
          data={renderData}
          layout="vertical"
          keys={keys}
          indexBy="x"
          margin={{ top: 5, right: 20, bottom: 40, left: 40 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={colors}
          theme={theme}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // tickValues:5,
            renderTick: HorizontalTick,
            // legend: "",
            legendPosition: "middle",
            legendOffset: 37,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 3]],
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -40,
            tickValues: tickValues,
          }}
          // legends={[
          //   {
          //     dataFrom: "keys",
          //     data: keys.map((id, index) => {
          //       if (keys.length > 4) {
          //         return {
          //           color: colors[index],
          //           id,
          //           label: id,
          //         };
          //       }
          //       return {
          //         color: colors[index],
          //         id,
          //         label: id,
          //       };
          //     }),
          //     anchor: "bottom",
          //     direction: "row",
          //     justify: false,
          //     translateX: 0,
          //     translateY: 70,
          //     itemsSpacing: 10,
          //     itemWidth: 110,
          //     itemHeight: 20,
          //     itemDirection: "left-to-right",
          //     itemOpacity: 0.85,
          //     symbolSize: 18,
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemOpacity: 1,
          //         },
          //       },
          //     ],
          //   },
          // ]}
          ariaLabel="Payer responses"
        />
      </div>
      <div
        className="flex justify-evenly flex-wrap"
        // style={{ marginLeft: 70, marginRight: 150, width: "100%" }}
      >
        {keys.map((item, i) => {
          return (
            <div className="flex  items-center justify-around">
              <div
                className="h-3 w-3 mr-1"
                style={{ backgroundColor: colors[i] }}
              ></div>
              <p className="mb-0 text-sm">{item}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BarChart;
