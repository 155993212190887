import { message, Tag, Menu, Progress, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import SurveySummaryStatistics from "./SurveySummaryStatistics";
import SurveySummaryResponses from "./SurveySummaryResponses";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import { BarChartOutlined, DownloadOutlined } from "@ant-design/icons";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-data-export";
import pptxgen from "pptxgenjs";
import { request } from "../../App";

const baseColors = [
  ["20BDAA", "57D1C2", "99E4DC", "CAF2ED"], //verbiter
  ["FDB571", "FDCB9C", "FEE1C6", "FEF0E2"], //orpiment
  ["DB6C03", "E2862F", "EAA25F", "EDB078"], //orpiment - dark
  ["1680a4", "3f9abf", "5fb5db", "7acfe5"], //blue
  ["4E944F", "83BD75", "B4E197", "E9EFC0"], //green
  ["e34381", "eb5b93", "f796bb", "fcbad4"], //pink
  ["44446F", "4D4C7D", "827397", "E9D5DA"], //navy blue
  ["ad3099", "bf5eaf", "d18cc6", "e3badd"], //purple
  ["a77281", "c4a1ab", "d7c0c7", "ebe0e3"], //beige
  ["2F8F9D", "3BACB6", "82DBD8", "B3E8E5"], //teal
  ["fea610", "ffb446", "ffc26c", "ffd090"], //orange
];

const firstColors = [
  "20BDAA",
  "FDB571",
  "DB6C03",
  "1680a4",
  "4E944F",
  "e34381",
  "44446F",
  "ad3099",
  "a77281",
  "2F8F9D",
  "fea610",
];

function SurveySummary({ SurveyStore, CurrentSurveyStore }) {
  const ref = useRef();
  let storeData = SurveyStore.returnData();
  const [basicSurveyData, setBasicSurveyData] = useState([]);
  const [publishedSurveyData, setPublishedSurveyData] = useState([]);
  const [responseTableData, setResponseTableData] = useState([]);
  const [check, setCheck] = useState(true);
  const [isSurveyResponseEmpty, setIsSurveyResponseEmpty] = useState(false);
  const [keySelected, setKeySelected] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function compare(a, b) {
    if (a.field < b.field) {
      return -1;
    }
    if (a.field > b.field) {
      return 1;
    }
    return 0;
  }

  //define a groupby function which can be used to group data based on the split selected.
  Array.prototype.applySplit = function (field) {
    let groupedArr = [];
    this.forEach(function (e) {
      //look for an existent group
      let group = groupedArr.find((g) => g["field"] === e[field]);
      if (group == undefined) {
        //add new group if it doesn't exist
        group = { field: e[field], groupList: [] };
        groupedArr.push(group);
      }

      //add the element to the group
      group.groupList.push(e);
    });
    groupedArr = groupedArr.sort(compare);

    // if split type is payer type or payer role, we need to apply split according to each country
    // for eg- country - payerType1, country-payerType2
    if (field === "type") {
      let copy = [];
      for (let i = 0; i < groupedArr.length; i++) {
        let tempField = groupedArr[i]["field"];
        let countries = [];
        //get all countries for this payer type
        groupedArr[i]["groupList"].map((item, j) => {
          countries.push(item["country"]);
        });
        let uniqueCountries = countries.filter((c, index) => {
          return countries.indexOf(c) === index;
        });

        for (let j = 0; j < uniqueCountries.length; j++) {
          let tempCopyObject = {
            field: uniqueCountries[j] + " - " + tempField, //country-payerType
            groupList: [], //array containing original data for selected country and payer type
          };

          for (let k = 0; k < groupedArr[i]["groupList"].length; k++) {
            if (
              groupedArr[i]["groupList"][k]["country"] === uniqueCountries[j] &&
              groupedArr[i]["groupList"][k]["type"] === tempField
            ) {
              //if country and payer type matches
              tempCopyObject["groupList"].push(groupedArr[i]["groupList"][k]);
            }
          }
          if (tempCopyObject["groupList"].length !== 0)
            copy.push(tempCopyObject);
        }
      }
      copy = copy.sort(compare);
      let tempColors = Array(copy.length).fill("");
      let countries = [];
      for (let i = 0; i < copy.length; i++) {
        countries.push(copy[i]["field"].split(" - ")[0]);
      }
      let uniqueCountries = countries.filter((c, index) => {
        return countries.indexOf(c) === index;
      });

      for (let i = 0; i < uniqueCountries.length; i++) {
        let k = 0;
        for (let j = 0; j < copy.length; j++) {
          if (copy[j]["field"].split(" - ")[0] === uniqueCountries[i]) {
            tempColors[j] =
              baseColors[i % baseColors.length][
                k++ % baseColors[i % baseColors.length].length
              ];
          }
        }
      }

      //process copy's data into ppt format
      let tempPPTData = [];
      for (let i = 0; i < copy.length; i++) {
        let tempPPTObject = {};
        tempPPTObject["name"] = copy[i]["field"];
        let tempPPTObjectLabels = Object.values(
          copy[i]["groupList"][0]["responseOptions"]
        );
        tempPPTObject["labels"] = tempPPTObjectLabels;
        let tempPPTObjectValues = [];
        for (let k = 0; k < tempPPTObjectLabels.length; k++)
          tempPPTObjectValues.push(null);
        for (let k = 0; k < copy[i]["groupList"].length; k++) {
          tempPPTObjectValues[
            tempPPTObjectLabels.indexOf(copy[i]["groupList"][k]["response"][0])
          ]++;
        }
        tempPPTObject["values"] = tempPPTObjectValues;
        tempPPTData.push(tempPPTObject);
      }
      return [copy, tempColors, tempPPTData];
    } else if (field === "role") {
      let copy = [];
      for (let i = 0; i < groupedArr.length; i++) {
        let tempField = groupedArr[i]["field"];
        let countries = [];
        //get all countries for this payer role
        groupedArr[i]["groupList"].map((item, j) => {
          countries.push(item["country"]);
        });
        let uniqueCountries = countries.filter((c, index) => {
          return countries.indexOf(c) === index;
        });

        for (let j = 0; j < uniqueCountries.length; j++) {
          let tempCopyObject = {
            field: uniqueCountries[j] + " - " + tempField, //country-payerRole
            groupList: [], //array containing original data for selected country and payer role
          };

          for (let k = 0; k < groupedArr[i]["groupList"].length; k++) {
            if (
              groupedArr[i]["groupList"][k]["country"] === uniqueCountries[j] &&
              groupedArr[i]["groupList"][k]["role"] === tempField
            ) {
              //if country and payer role matches
              tempCopyObject["groupList"].push(groupedArr[i]["groupList"][k]);
            }
          }
          if (tempCopyObject["groupList"].length !== 0)
            copy.push(tempCopyObject);
        }
      }
      copy = copy.sort(compare);
      let tempColors = Array(copy.length).fill("");
      let countries = [];
      for (let i = 0; i < copy.length; i++) {
        countries.push(copy[i]["field"].split(" - ")[0]);
      }
      let uniqueCountries = countries.filter((c, index) => {
        return countries.indexOf(c) === index;
      });
      for (let i = 0; i < uniqueCountries.length; i++) {
        let k = 0;
        for (let j = 0; j < copy.length; j++) {
          if (copy[j]["field"].split(" - ")[0] === uniqueCountries[i]) {
            tempColors[j] =
              baseColors[i % baseColors.length][
                k++ % baseColors[i % baseColors.length].length
              ];
          }
        }
      }

      //process copy's data into ppt format
      let tempPPTData = [];
      for (let i = 0; i < copy.length; i++) {
        let tempPPTObject = {};
        tempPPTObject["name"] = copy[i]["field"];
        let tempPPTObjectLabels = Object.values(
          copy[i]["groupList"][0]["responseOptions"]
        );
        tempPPTObject["labels"] = tempPPTObjectLabels;
        let tempPPTObjectValues = [];
        for (let k = 0; k < tempPPTObjectLabels.length; k++)
          tempPPTObjectValues.push(null);
        for (let k = 0; k < copy[i]["groupList"].length; k++) {
          tempPPTObjectValues[
            tempPPTObjectLabels.indexOf(copy[i]["groupList"][k]["response"][0])
          ]++;
        }
        tempPPTObject["values"] = tempPPTObjectValues;
        tempPPTData.push(tempPPTObject);
      }
      return [copy, tempColors, tempPPTData];
    }

    //for split by country, create ppt Data
    let tempColors = Array(groupedArr.length).fill("");
    let countries = [];
    for (let i = 0; i < groupedArr.length; i++) {
      countries.push(groupedArr[i]["field"]);
    }

    for (let i = 0; i < countries.length; i++) {
      let k = 0;
      for (let j = 0; j < groupedArr.length; j++) {
        if (groupedArr[j]["field"] === countries[i]) {
          tempColors[j] =
            baseColors[i % baseColors.length][
              k++ % baseColors[i % baseColors.length].length
            ];
        }
      }
    }
    let tempPPTData = [];
    for (let i = 0; i < groupedArr.length; i++) {
      let tempPPTObject = {};
      tempPPTObject["name"] = groupedArr[i]["field"];
      let tempPPTObjectLabels = Object.values(
        groupedArr[i]["groupList"][0]["responseOptions"]
      );
      tempPPTObject["labels"] = tempPPTObjectLabels;
      let tempPPTObjectValues = [];
      for (let k = 0; k < tempPPTObjectLabels.length; k++)
        tempPPTObjectValues.push(null);
      for (let k = 0; k < groupedArr[i]["groupList"].length; k++) {
        tempPPTObjectValues[
          tempPPTObjectLabels.indexOf(
            groupedArr[i]["groupList"][k]["response"][0]
          )
        ]++;
      }
      tempPPTObject["values"] = tempPPTObjectValues;
      tempPPTData.push(tempPPTObject);
    }
    return [groupedArr, tempColors, tempPPTData];
  };

  Array.prototype.max = function () {
    //return max in an array
    return Math.max.apply(null, this);
  };

  useEffect(() => {
    setIsLoading(true);
    const currentStoreData = CurrentSurveyStore.getCurrentSurveyData();
    if (
      currentStoreData !== null &&
      currentStoreData !== undefined &&
      currentStoreData.length !== 0 &&
      storeData[0]["surveyID"] !== 0
    ) {
      setCheck(false);
      for (let i = 0; i < currentStoreData.length; i++) {
        if (currentStoreData[i]["surveyId"] === storeData[0]["surveyID"]) {
          setBasicSurveyData(currentStoreData[i]);
          break;
        }
      }
      //API to fetch payer's responses for a particular suvey
      //returns an array of objects, containing each question along with its responses  as an object
      request
        .get(
          APP_CONSTANTS.API_URL +
            "/PublishedSurveyInfo?ClientId=" +
            SurveyStore.getClientId() +
            "&SurveyId=" +
            storeData[0]["surveyID"]
        )
        .then((res) => {
          const result = res.data.result;
          // console.log("Result: from api", result);
          setIsLoading(false);
          if (result === null) {
            //if the returned response is empty
            setIsSurveyResponseEmpty(true);
            return;
          }
          setPublishedSurveyData(result);
        })
        .catch((err) => {
          // console.log({ err });
        });
    } else {
      setCheck(true);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  function getBarGraphData(data, type, split) {
    {
      //function which creates data in PPT format based on type of question and split selected
      if (type === "rating") {
        if (split === "none") {
          let tempColors = firstColors;
          let temp = [
            {
              name: "None split",
              labels: [],
              values: [],
            },
          ];
          let xAxisValues = Object.keys(data[0]["responseOptions"]);
          for (let i = 0; i < xAxisValues.length; i++) {
            let count = 0;
            for (let j = 0; j < data.length; j++) {
              if (data[j]["response"][0] === xAxisValues[i]) count++;
            }
            if (i === 0) {
              temp[0]["labels"] = [xAxisValues[i]];
              temp[0]["values"] = [count];
            } else {
              temp[0]["labels"].push(xAxisValues[i]);
              temp[0]["values"].push(count);
            }
          }
          return [temp, tempColors];
        } else {
          let tempSplit = data.applySplit(split);
          let tempColors = tempSplit[1];
          return [tempSplit[2], tempColors];
        }
      } else if (type === "single") {
        let tempSplit = data.applySplit(split);
        let tempColors = tempSplit[1];
        return [tempSplit[2], tempColors];
      } else if (type === "multi") {
        if (split === "none") {
          let tempColors = firstColors;
          let temp = [
            {
              name: "None split",
              labels: [],
              values: [],
            },
          ];
          let xAxisValues = Object.values(data[0]["responseOptions"]);
          for (let i = 0; i < xAxisValues.length; i++) {
            let count = 0;
            for (let j = 0; j < data.length; j++) {
              for (let k = 0; k < data[j]["response"].length; k++)
                if (data[j]["response"][k] === xAxisValues[i]) count++;
            }
            if (i === 0) {
              temp[0]["labels"] = [xAxisValues[i]];
              temp[0]["values"] = [count];
            } else {
              temp[0]["labels"].push(xAxisValues[i]);
              temp[0]["values"].push(count);
            }
          }
          return [temp, tempColors];
        } else {
          let tempSplit = data.applySplit(split);
          let splitData = tempSplit[0];
          let tempColors = tempSplit[1];
          let tempPPTData = [];
          for (let i = 0; i < splitData.length; i++) {
            let tempPPTObject = {
              name: splitData[i]["field"],
              labels: Object.values(
                splitData[i]["groupList"][0]["responseOptions"]
              ),
              values: [],
            };
            tempPPTObject["labels"].map((item, i) => {
              tempPPTObject["values"].push(0);
            });
            for (let j = 0; j < splitData[i]["groupList"].length; j++) {
              for (
                let k = 0;
                k < splitData[i]["groupList"][j]["response"].length;
                k++
              ) {
                tempPPTObject["values"][
                  tempPPTObject["labels"].indexOf(
                    splitData[i]["groupList"][j]["response"][k]
                  )
                ]++;
              }
            }
            tempPPTData.push(tempPPTObject);
          }
          for (let i = 0; i < tempPPTData.length; i++) {
            for (let j = 0; j < tempPPTData[i]["values"].length; j++) {
              if (tempPPTData[i]["values"][j] === 0) {
                tempPPTData[i]["values"][j] = null;
              }
            }
          }
          return [tempPPTData, tempColors];
        }
      } else if (type === "ranking") {
        if (split === "none") {
          let tempColors = firstColors;
          let temp = [
            {
              name: "None split",
              labels: [],
              values: [],
            },
          ];
          let xAxisValues = Object.values(data[0]["responseOptions"]);
          for (let i = 0; i < xAxisValues.length; i++) {
            let count = 0;
            for (let j = 0; j < data.length; j++) {
              for (let k = 0; k < data[j]["response"].length; k++)
                if (data[j]["response"][k] === xAxisValues[i]) count++;
            }
            if (i === 0) {
              temp[0]["labels"] = [xAxisValues[i]];
              temp[0]["values"] = [count];
            } else {
              temp[0]["labels"].push(xAxisValues[i]);
              temp[0]["values"].push(count);
            }
          }
          return [temp, tempColors];
        } else {
          let tempSplit = data.applySplit(split);
          let splitData = tempSplit[0];
          let tempColors = tempSplit[1];

          let tempPPTData = [];
          for (let i = 0; i < splitData.length; i++) {
            let tempPPTObject = {
              name: splitData[i]["field"],
              labels: Object.values(
                splitData[i]["groupList"][0]["responseOptions"]
              ),
              values: [],
            };
            tempPPTObject["labels"].map((item, i) => {
              tempPPTObject["values"].push(0);
            });
            for (let j = 0; j < splitData[i]["groupList"].length; j++) {
              for (
                let k = 0;
                k < splitData[i]["groupList"][j]["response"].length;
                k++
              ) {
                tempPPTObject["values"][
                  tempPPTObject["labels"].indexOf(
                    splitData[i]["groupList"][j]["response"][k]
                  )
                ]++;
              }
            }
            tempPPTData.push(tempPPTObject);
          }
          for (let i = 0; i < tempPPTData.length; i++) {
            for (let j = 0; j < tempPPTData[i]["values"].length; j++) {
              if (tempPPTData[i]["values"][j] === 0) {
                tempPPTData[i]["values"][j] = null;
              }
            }
          }
          return [tempPPTData, tempColors];
        }
      }
    }
  }

  function DownloadButton({ disabled }) {
    //the download button and icon to be displayed on screen
    if (disabled) {
      return (
        <div className="flex items-center justify-end">
          <DownloadOutlined
            style={{
              fontSize: 20,
              color: "teal",
              cursor: "not-allowed",
            }}
          />

          <p
            className="text-xs text-green-700 pl-2 mt-2"
            style={{ cursor: "not-allowed" }}
          >
            Download Summary
          </p>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-end">
        <DownloadOutlined
          onClick={() => message.loading("Downloading")}
          style={{
            fontSize: 20,
            color: "teal",
            cursor: "pointer",
          }}
        />

        <p
          onClick={() => message.loading("Downloading")}
          className="text-xs text-green-700 pl-2 mt-2"
          style={{ cursor: "pointer" }}
        >
          Download Summary
        </p>
      </div>
    );
  }

  function pptDownload() {
    //function to generate PPT
    try {
      let responseData = [...publishedSurveyData];
      let pres = new pptxgen();

      //create Title slide of the PPT
      let slide = pres.addSlide();
      slide.addText(storeData[0]["surveyName"], {
        x: "7%",
        y: "32%",
        w: "85%",
        h: "15%",
        color: "d77f0e",
        fontSize: 66,
        align: pres.AlignH.center,
        bold: true,
      });
      slide.addText("Survey Statistics", {
        x: "7%",
        y: "50%",
        w: "85%",
        h: "15%",
        color: "27a6a4",
        fontSize: 40,
        align: pres.AlignH.center,
      });

      //add each question and its responses in the PPT
      storeData.map((item, i) => {
        if (item["quesTitle"] !== "Optional") {
          let questionResponses = [];
          for (let j = 0; j < responseData.length; j++) {
            if (
              //get all responses for particular question
              responseData[j]["quesID"] === item["quesID"] &&
              responseData[j]["payerResponses"].length !== 0 &&
              responseData[j]["quesTitle"] !== "Optional"
            )
              questionResponses.push(responseData[j]);
          }
          let pptData = [];
          //get all required information to be added in PPT
          if (
            questionResponses !== undefined &&
            questionResponses !== null &&
            questionResponses.length !== 0
          ) {
            if (questionResponses[0]["quesType"] === 1) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  comment: item["payerComment"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 2) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 3) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  comment: item["payerComment"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 5) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 6) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 7) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  comment: item["payerComment"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            } else if (questionResponses[0]["quesType"] === 4) {
              let temp = [];
              questionResponses.map((item, index) => {
                temp.push({
                  country: item["payerCountry"],
                  response: item["payerResponses"],
                  type: item["payerType"],
                  role: item["payerRole"],
                  responseOptions: item["responseOptions"],
                  payerID: item["payerID"],
                  respondant_id: item["respondant_id"],
                });
              });
              pptData = [...temp];
            }
          }
          //create a slide depending on question type
          if (item["quesType"] === 1 && item["quesTitle"] !== "Optional") {
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            let y = 25;
            let nLines = 0;
            for (let res = 0; res < pptData.length; res++) {
              if (y >= 80) {
                //add a new slide if height exceeds 100%
                y = 10;
                slide = pres.addSlide();
              }
              let payerHeader =
                pptData[res]["country"] +
                ", " +
                pptData[res]["type"] +
                ", " +
                pptData[res]["role"] +
                " - " +
                pptData[res]["respondant_id"];
              slide.addText(payerHeader, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: "5%",
                color: "d77f0e",
                fontSize: 12,
              });
              y = y + 3;
              if (
                pptData[res]["comment"] === undefined ||
                pptData[res]["comment"] === null
              ) {
                pptData[res]["comment"] = "No comments provided by Payer!";
              }
              let PPTComment = pptData[res]["comment"].split("\n").join(" ");
              nLines = Math.ceil(PPTComment.length / 120);
              slide.addText(PPTComment, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: `${nLines * 5}%`,
                color: "000000",
                fontSize: 12,
              });
              y = y + nLines * 5;

              if (y >= 90) {
                //add a new slide if height exceeds 100%
                y = 10;
                slide = pres.addSlide();
              }
            }
          } else if (item["quesType"] === 2) {
            // rating question
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });

            let barData = getBarGraphData(pptData, "rating", "none");
            let renderBarData = barData[0];
            let barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            let optsChartBar4 = {
              x: 0.4,
              y: "25%",
              w: 9,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: firstColors[0],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            barData = getBarGraphData(pptData, "rating", "country");
            renderBarData = barData[0];
            skipInterval = 1;

            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            optsChartBar4 = {
              x: 0.4,
              y: "25%",
              w: 9,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },
              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            barData = getBarGraphData(pptData, "rating", "type");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            optsChartBar4 = {
              x: 0.4,
              y: "25%",
              w: 9,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Response by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            barData = getBarGraphData(pptData, "rating", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            optsChartBar4 = {
              x: 0.4,
              y: "25%",
              w: 9,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by splt Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
          } else if (item["quesType"] === 3) {
            //single select and open ended
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });

            let temp = [];
            for (let i = 0; i < pptData.length; i++) {
              temp.push(pptData[i]["response"][0]);
            }
            let values = [...new Set(temp)];
            let tempRenderData = [];
            let tempRenderDataObject = {
              name: "Pie Chart",
              labels: [],
              values: [],
            };

            for (let i = 0; i < values.length; i++) {
              let count = 0;
              for (let j = 0; j < pptData.length; j++) {
                if (pptData[j]["response"][0] === values[i]) count++;
              }
              tempRenderDataObject["labels"].push(values[i]);
              tempRenderDataObject["values"].push(count);
            }
            tempRenderData.push(tempRenderDataObject);
            let barHeights = [];
            for (let i = 0; i < tempRenderData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < tempRenderData.length; i++) {
              for (let j = 0; j < tempRenderData[i]["values"].length; j++) {
                if (tempRenderData[i]["values"][j] !== null)
                  barHeights[j] += Number(tempRenderData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide.addChart(pres.charts.PIE, tempRenderData, {
              x: 3,
              y: 1.5,
              w: 4.2,
              h: 3.2,
              chartArea: { fill: { color: "F1F1F1" } },

              legendPos: "left",
              legendFontFace: "Aerial",
              showLegend: true,

              showLeaderLines: true,
              showPercent: false,
              showValue: true,
              chartColors: firstColors,
              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontSize: 8,
              dataLabelPosition: "bestFit", // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            });

            let barData = getBarGraphData(pptData, "single", "country");
            let renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            let optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "single", "type");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "single", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            slide.addText("Payer Comments", {
              x: "7%",
              y: "20%",
              w: "85%",
              h: "5%",
              color: "27a6a4",
              fontSize: 17,
              bold: true,
            });
            let y = 25;
            let nLines = 0;
            for (let res = 0; res < pptData.length; res++) {
              if (y >= 80) {
                y = 10;
                slide = pres.addSlide();
              }
              let payerHeader =
                pptData[res]["country"] +
                ", " +
                pptData[res]["type"] +
                ", " +
                pptData[res]["role"] +
                " - " +
                pptData[res]["respondant_id"];
              slide.addText(payerHeader, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: "5%",
                color: "d77f0e",
                fontSize: 12,
              });
              y = y + 3;
              if (
                pptData[res]["comment"] === undefined ||
                pptData[res]["comment"] === null
              ) {
                pptData[res]["comment"] = "No comments provided by Payer! ";
              }
              let PPTComment = pptData[res]["comment"].split("\n").join(" ");
              nLines = Math.ceil(PPTComment.length / 120);
              slide.addText(PPTComment, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: `${nLines * 5}%`,
                color: "000000",
                fontSize: 12,
              });
              y = y + nLines * 5;
            }
          } else if (item["quesType"] === 4) {
            //ranking
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });

            let barData = getBarGraphData(pptData, "ranking", "none");
            let renderBarData = barData[0];
            let barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            let optsChartBar4 = {
              x: 0.4,
              y: "18%",
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: firstColors[0],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              // showLegend: true,
              // legendPos: "t",
              // legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "ranking", "country");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
            barData = getBarGraphData(pptData, "ranking", "type");
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            renderBarData = barData[0];
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "ranking", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
          } else if (item["quesType"] === 5) {
            let slide = pres.addSlide();

            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            let temp = [];
            for (let i = 0; i < pptData.length; i++) {
              temp.push(pptData[i]["response"][0]);
            }
            let values = [...new Set(temp)];
            let tempRenderData = [];
            let tempRenderDataObject = {
              name: "Pie Chart",
              labels: [],
              values: [],
            };

            for (let i = 0; i < values.length; i++) {
              let count = 0;
              for (let j = 0; j < pptData.length; j++) {
                if (pptData[j]["response"][0] === values[i]) count++;
              }
              tempRenderDataObject["labels"].push(values[i]);
              tempRenderDataObject["values"].push(count);
            }
            tempRenderData.push(tempRenderDataObject);
            let barHeights = [];
            for (let i = 0; i < tempRenderData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < tempRenderData.length; i++) {
              for (let j = 0; j < tempRenderData[i]["values"].length; j++) {
                if (tempRenderData[i]["values"][j] !== null)
                  barHeights[j] += Number(tempRenderData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide.addChart(pres.charts.PIE, tempRenderData, {
              x: 3,
              y: 1.5,
              w: 4.2,
              h: 3.2,
              chartArea: { fill: { color: "F1F1F1" } },

              legendPos: "left",
              legendFontFace: "Aerial",
              showLegend: true,

              showLeaderLines: true,
              showPercent: false,
              showValue: true,
              chartColors: firstColors,
              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontSize: 8,
              dataLabelPosition: "bestFit", // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            });

            let barData = getBarGraphData(pptData, "single", "country");
            let renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            let optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
            barData = getBarGraphData(pptData, "single", "type");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "single", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
          } else if (item["quesType"] === 6) {
            //multi
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });

            let barData = getBarGraphData(pptData, "multi", "none");
            let renderBarData = barData[0];
            let barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            let optsChartBar4 = {
              x: 0.4,
              y: "18%",
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: firstColors[0],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              // showLegend: true,
              // legendPos: "t",
              // legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "multi", "country");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
            barData = getBarGraphData(pptData, "multi", "type");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "multi", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);
          } else if (item["quesType"] === 7) {
            //multi
            let slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });

            let barData = getBarGraphData(pptData, "multi", "none");
            let renderBarData = barData[0];
            let barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            let maxBarHeight = barHeights.max();
            let skipInterval = Math.floor(maxBarHeight / 10) + 1;
            let optsChartBar4 = {
              x: 0.4,
              y: "18%",
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: firstColors[0],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              // showLegend: true,
              // legendPos: "t",
              // legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Consolidated Summary",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "multi", "country");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "multi", "type");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Type",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            barData = getBarGraphData(pptData, "multi", "role");
            renderBarData = barData[0];
            barHeights = [];
            for (let i = 0; i < renderBarData[0]["values"].length; i++) {
              barHeights.push(0);
            }
            for (let i = 0; i < renderBarData.length; i++) {
              for (let j = 0; j < renderBarData[i]["values"].length; j++) {
                if (renderBarData[i]["values"][j] !== null)
                  barHeights[j] += Number(renderBarData[i]["values"][j]);
              }
            }
            maxBarHeight = barHeights.max();
            skipInterval = Math.floor(maxBarHeight / 10) + 1;
            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            optsChartBar4 = {
              x: 0.4,
              y: 1,
              w: 9.0,
              h: 4.6,
              chartArea: { fill: { color: "F1F1F1" } },

              barDir: "col",
              barGapWidthPct: 25,
              chartColors: barData[1],
              chartColorsOpacity: 100,
              barGrouping: "stacked",

              catAxisLabelColor: "000000",
              catAxisLabelFontFace: "Times",
              catAxisLabelFontSize: 11,
              catAxisOrientation: "minMax",

              dataBorder: { pt: "1", color: "F1F1F1" },
              dataLabelColor: "FFFFFF",
              dataLabelFontFace: "Arial",
              dataLabelFontSize: 7,
              dataLabelPosition: "ctr",
              showValue: true,
              valGridLine: { style: "none" },
              valAxisMajorUnit: skipInterval,

              showLegend: true,
              legendPos: "t",
              legendColor: "000000",
              showTitle: true,
              titleColor: "000000",
              titleFontSize: 12,
              title: "Responses by Split: Country- Payer Expertise",
            };
            slide.addChart(pres.charts.BAR, renderBarData, optsChartBar4);

            slide = pres.addSlide();
            slide.addText(i + 1, {
              x: "3%",
              y: "4%",
              w: 0.68,
              h: 0.68,
              shape: pres.ShapeType.ellipse,
              align: pres.AlignH.center,
              fill: { color: "27a6a4" },
              color: "f5f5f5",
            });
            slide.addText(item["quesText"], {
              x: "10%",
              y: "5%",
              w: "85%",
              h: "10%",
              color: "000000",
              fontSize: 16,
            });
            slide.addText("Payer Comments", {
              x: "7%",
              y: "20%",
              w: "85%",
              h: "5%",
              color: "27a6a4",
              fontSize: 17,
              bold: true,
              // fill: { color: "F1F1F1" },
            });
            let y = 25;
            let nLines = 0;
            for (let res = 0; res < pptData.length; res++) {
              if (y >= 80) {
                y = 10;
                slide = pres.addSlide();
              }
              let payerHeader =
                pptData[res]["country"] +
                ", " +
                pptData[res]["type"] +
                ", " +
                pptData[res]["role"] +
                " - " +
                pptData[res]["respondant_id"];
              slide.addText(payerHeader, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: "5%",
                color: "d77f0e",
                fontSize: 12,
              });
              y = y + 3;
              if (
                pptData[res]["comment"] === undefined ||
                pptData[res]["comment"] === null
              ) {
                pptData[res]["comment"] = "No comments provided by Payer! ";
              }
              let PPTComment = pptData[res]["comment"].split("\n").join(" ");
              nLines = Math.ceil(PPTComment.length / 120);
              slide.addText(PPTComment, {
                x: "7%",
                y: `${y}%`,
                w: "85%",
                h: `${nLines * 5}%`,
                color: "000000",
                fontSize: 12,
              });
              y = y + nLines * 5;
            }
          }
        }
      });
      const fileName = storeData[0]["surveyName"] + "_Statistics.pptx";
      pres.writeFile({ fileName: fileName });
    } catch (error) {
      // console.log(error);
      message.error("Error!" + error);
    }
  }

  function workingHoursBetweenDates(endDate) {
    let startDate = new Date(); //current date time
    const offset = startDate.getTimezoneOffset();
    startDate.setMinutes(startDate.getMinutes() + offset);
    var minutesWorked = 0;
    endDate = new Date(endDate);
    if (endDate < startDate) {
      message.error("Error!");
      return 0;
    }

    var current = startDate;
    // Define working hours range
    var workHoursStart = 0;
    var workHoursEnd = 24;
    var includeWeekends = true;
    // Loop while currentDate is less than end Date (by minutes)
    while (current <= endDate) {
      // Is the current time within a work day and if it occurs on a weekend or not
      if (
        current.getHours() >= workHoursStart &&
        current.getHours() < workHoursEnd &&
        (includeWeekends
          ? current.getDay() !== 0 && current.getDay() !== 6
          : true)
      ) {
        minutesWorked++;
      }

      // Increment current time
      current.setTime(current.getTime() + 1000 * 60);
    }

    // Return the number of hours
    return minutesWorked / 60;
  }

  function ExportCSV() {
    //download survey responses in excel format
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    //add columns of documents in solumns attribute
    //add responses in data attribute
    const multiDataSet = [
      {
        columns: [
          {
            title: "Participants",
            width: { wpx: 200 },
            style: {
              font: { bold: true },
              border: {
                bottom: { style: "medium", color: { rgb: "00000000" } },
                top: { style: "medium", color: { rgb: "00000000" } },
                left: { style: "medium", color: { rgb: "00000000" } },
                right: { style: "medium", color: { rgb: "00000000" } },
              },
            },
          },
        ],
        data: [],
      },
    ];

    for (let i = 0; i < storeData.length; i++) {
      multiDataSet[0].columns.push({
        title: storeData[i]["quesText"],
        width: { wpx: 200 },
        style: {
          font: { bold: true },
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
    }

    for (let i = 0; i < responseTableData.length; i++) {
      let temp = [];
      temp.push({
        value: responseTableData[i]["participant"],
        style: {
          border: {
            bottom: { style: "medium", color: { rgb: "00000000" } },
            top: { style: "medium", color: { rgb: "00000000" } },
            left: { style: "medium", color: { rgb: "00000000" } },
            right: { style: "medium", color: { rgb: "00000000" } },
          },
        },
      });
      for (let j = 0; j < storeData.length; j++) {
        let tempp = Object.keys(responseTableData[i]);
        for (let k = 0; k < tempp.length; k++) {
          if (Number(tempp[k]) === Number(storeData[j]["quesID"])) {
            if (responseTableData[i][tempp[k]] === null) {
              temp.push({
                value: "-",
                style: {
                  border: {
                    bottom: { style: "medium", color: { rgb: "00000000" } },
                    top: { style: "medium", color: { rgb: "00000000" } },
                    left: { style: "medium", color: { rgb: "00000000" } },
                    right: { style: "medium", color: { rgb: "00000000" } },
                  },
                },
              });
            } else {
              temp.push({
                value: responseTableData[i][tempp[k]],
                style: {
                  border: {
                    bottom: { style: "medium", color: { rgb: "00000000" } },
                    top: { style: "medium", color: { rgb: "00000000" } },
                    left: { style: "medium", color: { rgb: "00000000" } },
                    right: { style: "medium", color: { rgb: "00000000" } },
                  },
                },
              });
            }
            break;
          }
        }
      }
      multiDataSet[0].data.push(temp);
    }

    return (
      <ExcelFile
        filename={storeData[0]["surveyName"] + "_ResponseSummary"}
        element={<DownloadButton disabled={false} />}
      >
        <ExcelSheet dataSet={multiDataSet} name="Responses" />
      </ExcelFile>
    );
  }

  useEffect(() => {
    // preprocess to convert data to table format for responses screen
    if (
      SurveyStore.returnData() !== undefined &&
      SurveyStore.returnData() !== null &&
      SurveyStore.returnData().length !== 0
    ) {
      //check if no payers have responded
      let count = 0; //keeps the count of payers' questions for which there is no response
      // for (let k = 0; k < publishedSurveyData.length; k++) {
      //   if (
      //     (publishedSurveyData[k]["quesType"] === 1 &&
      //       publishedSurveyData[k]["payerComment"] === null) ||
      //     publishedSurveyData[k]["payerResponses"].length === 0 ||
      //     publishedSurveyData[k]["payerResponses"] === null ||
      //     publishedSurveyData[k]["payerResponses"] === undefined
      //   ) {
      //     count++;
      //   }
      // }
      // if (
      //   count === publishedSurveyData.length &&
      //   publishedSurveyData.length !== 0
      // ) {
      //   //if no question has been answered
      //   setIsSurveyResponseEmpty(true);
      //   return;
      // }
      let qIds = [];
      const tempStoreData = SurveyStore.returnData();
      for (let i = 0; i < tempStoreData.length; i++) {
        qIds.push(tempStoreData[i]["quesID"]);
      }
      const payerIds = [
        ...new Set(publishedSurveyData.map((item) => item.payerID)),
      ];

      let participantData = [];
      for (let i = 0; i < payerIds.length; i++) {
        for (let k = 0; k < publishedSurveyData.length; k++) {
          if (publishedSurveyData[k]["payerID"] === payerIds[i]) {
            participantData.push({
              payerID: payerIds[i],
              participant: String(
                publishedSurveyData[k]["payerCountry"] +
                  ", " +
                  publishedSurveyData[k]["payerType"] +
                  ", " +
                  publishedSurveyData[k]["payerRole"] +
                  " - " +
                  publishedSurveyData[k]["respondant_id"]
              ),
              status: publishedSurveyData[k]["status"],
            });
            break;
          }
        }
      }

      let temp = [];
      for (let i = 0; i < participantData.length; i++) {
        let payerIdData = {};
        payerIdData["participant"] = participantData[i]["participant"];
        payerIdData["status"] = participantData[i]["status"];
        for (let j = 0; j < qIds.length; j++) {
          for (let k = 0; k < publishedSurveyData.length; k++) {
            if (
              publishedSurveyData[k]["quesID"] === qIds[j] &&
              // publishedSurveyData[k]["payerResponses"].length !== 0 &&
              publishedSurveyData[k]["payerID"] ===
                participantData[i]["payerID"]
            ) {
              if (
                publishedSurveyData[k]["quesType"] === 1 ||
                publishedSurveyData[k]["quesType"] === 3 ||
                publishedSurveyData[k]["quesType"] === 7
              ) {
                let comments = publishedSurveyData[k]["payerComment"];

                let choices =
                  publishedSurveyData[k]["payerResponses"].join(", ");
                if (choices.length === 0) {
                  payerIdData[[qIds[j]]] = comments;
                } else if (comments === null || comments === undefined) {
                  payerIdData[[qIds[j]]] = choices;
                } else {
                  payerIdData[[qIds[j]]] = choices + ", " + comments;
                }
              } else {
                payerIdData[[qIds[j]]] =
                  publishedSurveyData[k]["payerResponses"].join(", ");
              }
              break;
            }
          }
        }
        if (Object.keys(payerIdData).length > 1) temp.push(payerIdData);
      }
      setResponseTableData(temp);
    }
  }, [publishedSurveyData]);

  function handleMenuClick(e) {
    setKeySelected(e.key);
  }

  //sleep function to delay pdf downloader rendering (for successful state updates)
  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  return (
    <div style={{ minHeight: "87.5vh" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "86.8vh" }}
        >
          <div>
            <div className="flex justify-center items-center">
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
            <p className="text-black font-semibold text-xl mt-4">
              Creating dashboard, please wait...
            </p>
          </div>
        </div>
      ) : check ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "86.5vh" }}
        />
      ) : (
        <div>
          <div className="banner flex" style={{ minHeight: "6rem" }}>
            <div className="w-1/3">
              <p className="text-white font-bold text-xl pl-36  py-4 mb-0">
                {storeData[0]["surveyName"]}&nbsp;&nbsp;{" "}
                {basicSurveyData["surveyStatus"].map((stat) => {
                  if (stat === "Published")
                    return (
                      <Tag style={{ fontSize: "0.7rem" }} color="blue">
                        Published
                      </Tag>
                    );
                  else if (stat === "Completed")
                    return (
                      <Tag style={{ fontSize: "0.7rem" }} color="green">
                        Completed
                      </Tag>
                    );
                })}
              </p>
              <div className="text-white flex px-36 items-center text-sm mb-2">
                <p className="mb-0">
                  {basicSurveyData["deadline"].split("T")[0]}
                </p>
                &nbsp;{" "}
                <p className="text-white text-xs ml-4 mt-0 mb-0">Due Date</p>
              </div>
            </div>
            <div className="w-2/3 pr-36 flex justify-end items-center">
              <div
                id="summaryCard"
                style={{
                  borderRadius: 5,
                  alignItems: "center",
                  padding: "0.5rem",
                  width: "15rem",
                }}
                className="text-black rounded-lg"
              >
                <div className="text-xs mb-0 pl-1 flex items-center">
                  <p className="text-base mb-0">Accepted</p>
                  <p className="text-xl ml-4 mb-0">
                    {basicSurveyData["totalAccepted"]}
                  </p>
                  <p className="mb-0 mt-1">
                    /{basicSurveyData["totalApproached"]}
                  </p>
                </div>

                <Progress
                  style={{ width: "16.5rem" }}
                  percent={parseInt(
                    ((basicSurveyData["totalAccepted"] +
                      basicSurveyData["totalRejected"]) /
                      basicSurveyData["totalApproached"]) *
                      100
                  )}
                  format={(format) =>
                    `${Math.round(
                      (basicSurveyData["totalAccepted"] /
                        basicSurveyData["totalApproached"]) *
                        100
                    )}%`
                  }
                  strokeColor="#fc001f"
                  success={{
                    percent:
                      (basicSurveyData["totalAccepted"] /
                        basicSurveyData["totalApproached"]) *
                      100,
                    strokeColor: "#00AA67",
                  }}
                  trailColor="#DEDCDE"
                />
              </div>

              <div
                id="summaryCard"
                style={{
                  borderRadius: 5,
                  alignItems: "center",
                  padding: "0.5rem",
                  width: "15rem",
                }}
                className=" text-black ml-8 rounded-lg"
              >
                <div className="text-xs mb-0 pl-1 flex items-center">
                  <p className="text-base mb-0">Completed</p>
                  <p className="text-xl ml-4 mb-0">
                    {basicSurveyData["completedSurvey"]}
                  </p>
                  <p className="mb-0 mt-1">
                    /{basicSurveyData["totalApproached"]-basicSurveyData["totalRejected"]}
                  </p>
                </div>
                <Progress
                  style={{ width: "16.5rem" }}
                  percent={parseInt(
                    (basicSurveyData["completedSurvey"] /
                      (basicSurveyData["totalApproached"] - basicSurveyData["totalRejected"])) *
                      100
                  )}
                  strokeColor="#fc001f"
                  success={{
                    percent:
                      (basicSurveyData["completedSurvey"] /
                        (basicSurveyData["totalApproached"] - basicSurveyData["totalRejected"])) *
                      100,
                    strokeColor: "#00AA67",
                  }}
                  format={(format) =>
                    `${Math.round(
                      (basicSurveyData["completedSurvey"] /
                        (basicSurveyData["totalApproached"] - basicSurveyData["totalRejected"])) *
                        100
                    )}%`
                  }
                  trailColor="#DEDCDE"
                />
              </div>

              <div
                id="summaryCard"
                style={{
                  borderRadius: 5,
                  alignItems: "center",
                  padding: "0.5rem",
                  width: "15rem",
                }}
                className="text-black ml-8 rounded-lg"
              >
                <div className="text-xs mb-0 pl-1 flex items-center">
                  <p className="text-base mb-0">Due Date</p>
                  <div className="text-xl ml-4 flex mb-0">
                    {basicSurveyData["surveyStatus"][0] === "Completed"
                      ? 0
                      : Math.round(
                          workingHoursBetweenDates(basicSurveyData["deadline"])
                        )}
                    &nbsp;
                    <p className="text-sm mb-0 mt-1.5">Hours Left</p>
                  </div>
                </div>

                {basicSurveyData["surveyStatus"][0] === "Completed" ? (
                  <Progress
                    style={{ width: "18rem" }}
                    percent={100}
                    strokeColor="#DB6C03"
                    trailColor="#DEDCDE"
                  />
                ) : (
                  <Progress
                    style={{ width: "16.5rem" }}
                    percent={parseInt(
                      (1 -
                        Math.round(
                          workingHoursBetweenDates(basicSurveyData["deadline"])
                        ) /
                          120) *
                        100
                    )}
                    strokeColor="#DB6C03"
                    trailColor="#DEDCDE"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="px-32 bg-white flex">
            <div className="w-10/12">
              <Menu
                onClick={handleMenuClick}
                defaultSelectedKeys={"1"}
                mode="horizontal"
              >
                <Menu.Item key="1">Statistics</Menu.Item>
                <Menu.Item key="2">Responses</Menu.Item>
              </Menu>
            </div>
            {/* <Button onClick={pptDownload}>PPT</Button> */}
            <div className="w-2/12 flex items-center justify-end">
              {isSurveyResponseEmpty ? (
                <DownloadButton disabled={true} />
              ) : keySelected === "1" ? (
                // <ReactToPrint
                //   documentTitle={storeData[0]["surveyName"] + "_Stats"}
                //   onBeforeGetContent={async () => {
                //     document
                //       .getElementById("divToPrint")
                //       .classList.remove("hidden");
                //     await sleep(1000);
                //   }}
                //   onAfterPrint={() => {
                //     document
                //       .getElementById("divToPrint")
                //       .classList.toggle("hidden", true);
                //   }}
                //   trigger={() => (
                <div className="flex mr-6 items-center justify-end">
                  <DownloadOutlined
                    onClick={() => {
                      message.loading("Downloading!", [1]);
                      pptDownload();
                    }}
                    style={{
                      fontSize: 20,
                      color: "teal",
                      cursor: "pointer",
                    }}
                  />

                  <p
                    onClick={() => {
                      message.loading("Downloading!", [1]);
                      pptDownload();
                    }}
                    className="text-xs text-green-700 pl-2 mt-2"
                    style={{ cursor: "pointer" }}
                  >
                    Download Summary
                  </p>
                </div>
              ) : (
                // )}
                // content={() => ref.current}
                // />
                <ExportCSV />
              )}
            </div>
          </div>

          {isSurveyResponseEmpty ? (
            <div className="flex py-24 border-4 mt-20 mx-20 items-center justify-center">
              <div className="flex justify-center">
                <BarChartOutlined
                  style={{ fontSize: "5rem", color: "#27a6a4" }}
                />
              </div>
              <p className="text-2xl ml-4 pt-5 font-semibold">
                No Payer has responded yet!
              </p>
            </div>
          ) : keySelected === "1" ? (
            <SurveySummaryStatistics
              SurveyStore={SurveyStore}
              basicSurveyData={basicSurveyData}
              responseData={publishedSurveyData}
            />
          ) : (
            <SurveySummaryResponses
              SurveyStore={SurveyStore}
              responseData={responseTableData}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SurveySummary;
