import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ZSLogo from "../../zslogo.png";
import payerconnect from "../../payerconnect.png";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import ScaleLoader from "react-spinners/ScaleLoader";
import { request } from "../../App";

//API to fetch data for a particular payer and survey based on unique survey link
//returns an object
const getData = async (surveyLink) => {
  let ResultArray = [];
  await request
    .get(APP_CONSTANTS.API_URL + "/PayerStatusInfo?SurveyLink=" + surveyLink)
    .then((res) => {
      if (res.status === 200) {
        const result = res.data.result;
        ResultArray = result;
      }
    })
    .catch((err) => {
      if (err.response.status !== 401) {
        message.error("Error!");
        // console.log({ err });
      }
    });
  return ResultArray;
};
function PayerStatus({ PayerStatusStore }) {
  const [payerData, setPayerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    //call API to get surveyID, payerID, status based on surveyLink (which is captured from url)
    //and then add response data to PayerStatusStore
    setIsLoading(true);
    let surveyLink = String(window.location);
    // console.log("Surveylink", surveyLink);
    debugger;
    if (surveyLink !== "https://payerconnect.zsservices.com/payerstatus") {
      sessionStorage.setItem("OriginalsurveyLink", surveyLink);
    }
    let result = surveyLink
      .replaceAll("=", "%3D")
      .replaceAll(":", "%3A")
      .replaceAll("/", "%2F")
      .replaceAll("?", "%3F")
      .replaceAll("&", "%26");
    const splitSurveyLink = surveyLink.split("/");
    let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
    if (appInfo === null || appInfo === undefined) {
      return;
    }
    if (splitSurveyLink[splitSurveyLink.length - 1] === "payerstatus") {
      //if no surveyLink is present show null page
      setCheck(false);
      setIsLoading(false);
      // message.error("Unable to fetch survey!");
      return;
    }
    getData(result).then((output) => {
      if (output["surveyID"] === 0 || output["surveyID"] === undefined) {
        if (output === [] || output === null || output === undefined) {
          setIsLoading(true);
        } else {
          // message.error("No Survey Found!");

          setCheck(false);
        }
      } else {
        let toStore = output;
        toStore["surveyLink"] = result;
        // console.log("output", toStore);
        sessionStorage.setItem(
          "surveyLink",
          JSON.stringify({
            surveyLink: window.location.search,
          })
        );
        setPayerData(toStore);
        PayerStatusStore.addPayerStatusData(output);
        //check redirection if status is updated
        if (
          toStore["surveyStatus"] === "Completed" ||
          toStore["acceptanceExpiry"] ||
          toStore["completionExpiry"]
        ) {
          // add new here
          navigate("/closedsurvey");
          PayerStatusStore.increaseNavigationCounter();
        } else {
          if (
            toStore["payerStatus"] === "Accepted" ||
            toStore["payerStatus"] === "Pending"
          ) {
            setIsLoading(false);
            navigate("/payersurvey");
            PayerStatusStore.increaseNavigationCounter();
          } else if (toStore["payerStatus"] === "Rejected") {
            setIsLoading(false);
            navigate("/thankyour");
            PayerStatusStore.increaseNavigationCounter();
          } else if (toStore["payerStatus"] === "Completed") {
            setIsLoading(false);
            navigate("/thankyouc");
            PayerStatusStore.increaseNavigationCounter();
          }
        }
      }
      if (appInfo !== null && appInfo !== undefined) {
        setIsLoading(false);
      }
    });
  }, []);

  function acceptHandler() {
    // call API to update survey status to accepted and then navigate to next screen
    setIsLoading(true);
    const status = "Accepted";
    let temp = [
      {
        payerID: payerData["payerID"],
        surveyID: payerData["surveyID"],
        quesID: 0,
        quesTitle: "string",
        quesText: "string",
        quesCategory: "string",
        quesType: 0,
        responseOptions: {
          additionalProp1: "string",
          additionalProp2: "string",
          additionalProp3: "string",
        },
        surveyResponses: ["string"],
        comments: "string",
        ispersonal: true,
      },
    ];

    //API to update the payer status
    request
      .post(APP_CONSTANTS.API_URL + "/SaveResponses?status=" + status, temp)
      .then((response) => {
        // console.log("Status updated successfully", response);
        //since only the status is updated, the errorCode determines whether the status is updated or not
        if (Number(response.data.error.errorCode) === 200) {
          navigate("/payersurvey");
          PayerStatusStore.increaseNavigationCounter();
        } else message.error("Error");
      })
      .catch((err) => {
        message.error("Error!");
        // console.log(err);
        setIsLoading(false);
      });
  }
  function declineHandler() {
    setIsLoading(true);
    // call API to update survey status to accepted and then navigate to thank you screen
    const status = "Rejected";
    let temp = [
      {
        payerID: payerData["payerID"],
        surveyID: payerData["surveyID"],
        quesID: 0,
        quesTitle: "string",
        quesText: "string",
        quesCategory: "string",
        quesType: 0,
        responseOptions: {
          additionalProp1: "string",
          additionalProp2: "string",
          additionalProp3: "string",
        },
        surveyResponses: ["string"],
        comments: "string",
        ispersonal: true,
      },
    ];

    //API to update the payer status
    request
      .post(APP_CONSTANTS.API_URL + "/SaveResponses?status=" + status, temp)
      .then((response) => {
        // console.log("Status updated successfully", response);
        //since only the status is updated, the errorCode determines whether the status is updated or not
        if (Number(response.data.error.errorCode) === 200) {
          navigate("/thankyour");
          PayerStatusStore.increaseNavigationCounter();
        } else message.error("Error");
        setIsLoading(false);
      })
      .catch((err) => {
        message.error("Error!");
        // console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: "100vh" }}
    >
      {isLoading ? (
        <div
          className="flex loginCard justify-center items-center"
          style={{ height: "50vh", width: "50rem" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : check === true ? (
        <div
          className="globeBackground flex justify-center items-center"
          style={{ minHeight: "100vh", width: "100%" }}
        >
          <div
            className="loginCard p-4 px-8"
            style={{ minHeight: "24rem", width: "50rem" }}
          >
            <div className="flex justify-center">
              <img
                src={ZSLogo}
                style={{
                  width: "3rem",
                  height: "5rem",
                  marginRight: "0.5rem",
                  padding: "0.3rem",
                  marginLeft: "0.2rem",
                }}
              ></img>

              <div className="flex font-extrabold text-3xl mt-5">
                <p className="font-medium text-gray-600 ">ZS&nbsp;</p> Payer
                Connect
                <p
                  className="font-bold ml-1"
                  style={{
                    verticalAlign: "super",
                    fontSize: "0.6rem",
                    marginTop: "-0.3rem",
                  }}
                >
                  {" "}
                  TM
                </p>
              </div>
              <div className="my-0 pt-3">
                <img
                  src={payerconnect}
                  alt="payerconnect"
                  style={{
                    width: "3.5rem",
                    height: "3rem",
                    // padding: "0.3rem",
                    marginLeft: "0.5rem",
                  }}
                />
              </div>
            </div>
            <div className="mt-1">
              {payerData["therapyArea"] === null ||
              payerData["therapyArea"] === undefined ||
              payerData["therapyArea"] === "" ? (
                <p className="mb-1">
                  <b>Therapy Area:&nbsp;</b> N/A
                </p>
              ) : (
                <p className="mb-1">
                  <b>Therapy Area:&nbsp;</b> {payerData["therapyArea"]}
                </p>
              )}

              <p className="mb-1">
                <b>Type of Research:&nbsp;</b> {payerData["researchType"]}
              </p>
              <p className="mb-1">
                <b>Research Objective:&nbsp;</b>{" "}
                {payerData["surveyDescription"]}
              </p>

              <p className="mb-1">
                <b>Total Questions:&nbsp;</b> {payerData["questionCount"]}
              </p>
              {/* <p className="mb-1">
                <b>Estimated Time:&nbsp;</b>20 ~ 30 Minutes
              </p> */}
              <p className="text-blue-700 mt-4 text-center">
                By selecting <b>‘Accept’</b>, you agree to complete the survey
                within <b>(3)</b> business days; else, select <b>‘Decline’</b>
              </p>
            </div>
            <div className="mt-4 flex items-end justify-center">
              <Button
                style={{ width: "40%" }}
                onClick={acceptHandler}
                type="primary"
              >
                Accept
              </Button>
            </div>
            <div className="mt-3 flex items-end justify-center">
              <Button
                style={{ width: "40%" }}
                onClick={declineHandler}
                type="default"
              >
                Decline
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="globeBackground flex justify-center items-center"
          style={{ minHeight: "100vh", width: "100%" }}
        >
          <div
            className="loginCard p-4 px-8"
            style={{ minHeight: "16rem", width: "30rem" }}
          >
            <div className="flex justify-center">
              <img
                src={ZSLogo}
                style={{
                  width: "3rem",
                  height: "5rem",
                  marginRight: "0.5rem",
                  padding: "0.3rem",
                  marginLeft: "0.2rem",
                }}
              ></img>

              <div className="flex font-extrabold text-3xl mt-5">
                <p className="font-medium text-gray-600 ">ZS&nbsp;</p> Payer
                Connect
                <p
                  className="font-bold ml-1"
                  style={{
                    verticalAlign: "super",
                    fontSize: "0.6rem",
                    marginTop: "-0.3rem",
                  }}
                >
                  {" "}
                  TM
                </p>
              </div>
              <div className="my-0 pt-3">
                <img
                  src={payerconnect}
                  alt="payerconnect"
                  style={{
                    width: "3.5rem",
                    height: "3rem",
                    // padding: "0.3rem",
                    marginLeft: "0.5rem",
                  }}
                />
              </div>
            </div>
            <div className="flex items-center mt-12 text-lg justify-center">
              No survey details found!
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default PayerStatus;
