import Stepper from "../../component/Stepper";
import {
  Radio,
  Checkbox,
  Input,
  Card,
  Space,
  Modal,
  Button,
  message,
  Select,
  Tooltip,
} from "antd";
import { DownloadOutlined, FileAddOutlined } from "@ant-design/icons";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import ReactToPrint from "react-to-print";
import { request } from "../../App";

const { Option } = Select;

const getQuestionData = async (clientId, surveyId) => {
  // console.log(surveyId);
  let ResultArray = [];
  //API to get questions for a survey
  await request
    .get(
      APP_CONSTANTS.API_URL +
        "/EachSurveyInfo?ClientId=" +
        clientId +
        "&SurveyId=" +
        surveyId
    )
    .then((res) => {
      // console.log(res);
      const result = res.data.result;
      ResultArray = result;
      // console.log("survey name", result[0]["surveyName"]);
      // console.log("Result: from api", result);
    })
    .catch((err) => {
      // console.log({ err });
    });
  return ResultArray;
};

function ReviewSurvey({ SurveyStore, CurrentSurveyStore }) {
  const storeData = SurveyStore.returnData();
  const [isLoading, setIsLoading] = useState(false);
  const [isDrafted, setIsDrafted] = useState(false);
  const [isModalVisibleCancel, setIsModalVisibleCancel] = useState(false);
  const [isModalVisiblePrevious, setIsModalVisiblePrevious] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  let navigateToNext = false;
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function RespondentSelection() {
    //navigate to respondent screen
    navigateToNext = true;
    SaveDraftHandler();
  }

  //sleep function to delay pdf downloader rendering (for successful state updates)
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function preprocess(customResponses) {
    // function to preprocess rating based question's custom responses since it is not in obj format
    let tempCustomResponses = {};
    for (let i = 0; i < Number(customResponses[0]); i++) {
      tempCustomResponses[i + 1] = String(i + 1);
    }
    let toRender = (
      <div className="flex px-6">
        {Object.keys(tempCustomResponses).map((option, i) => {
          return (
            <div key={i} className="my-1 flex">
              <Radio defaultChecked={false} disabled={true}>
                {tempCustomResponses[option]}
              </Radio>
            </div>
          );
        })}
      </div>
    );
    return toRender;
  }

  const showModalCancel = () => {
    setIsModalVisibleCancel(true);
  };
  const showModalPrevious = () => {
    setIsModalVisiblePrevious(true);
  };

  const handleOk = () => {
    setIsModalVisibleCancel(false);
    setIsLoading(true);
    const storeData = SurveyStore.returnData();
    SurveyStore.deleteStoreData();
    // if (!SurveyStore.getIsEdited()) {
    //   if (isDrafted) {
    //     //delete from DB
    //     // console.log("calling delete api");
    //     //API to delete durvey
    //     axios
    //       .delete(
    //         APP_CONSTANTS.API_URL +
    //           "/DeleteSurvey?ClientId=" +
    //           storeData[0]["clientID"] +
    //           "&SurveyId=" +
    //           storeData[0]["surveyID"]
    //       )
    //       .then((res) => {
    //         if (res["status"] === 200) {
    //           // console.log(
    //             "Delete success >> Survey ID:",
    //             storeData[0]["surveyID"]
    //           );
    //           CurrentSurveyStore.deleteSurvey(storeData[0]["surveyID"]);
    //         } else {
    //           // console.log("error deleting survey");
    //         }
    //       })
    //       .catch((error) => {
    //         message.error("Error");
    //         // console.log(error);
    //         setIsLoading(false);
    //       })
    //       .then((res) => {
    //         setIsLoading(false);
    //         navigate("/currentsurveys");
    //       });
    //   } else {
    //     setIsLoading(false);
    //     navigate("/currentsurveys");
    //   }
    //   setIsDrafted(false);
    // } else {
    navigate("/currentsurveys");
    setIsLoading(true);
    // }
  };

  const handleOkPrevious = () => {
    setIsModalVisiblePrevious(false);
    SurveyStore.setIsEdited(true);
    navigate("/createsurveyquestions");
  };

  const handleCancel = () => {
    setIsModalVisibleCancel(false);
    setIsModalVisiblePrevious(false);
  };

  function SaveDraftHandler() {
    //define last saved page
    let lastSavedStep = 3;
    if (navigateToNext) {
      // if navigating to respondent screen
      lastSavedStep = 4;
    }

    window.scrollTo(0, 0);
    //send data to store as well as DB on both 'save as draft' button and 'Respondent Selection' button
    // console.log("DATA", storeData);
    if (
      storeData[0]["quesText"] === "" ||
      storeData[0]["quesText"] === null ||
      storeData[0]["quesText"] === undefined ||
      storeData[0]["quesTitle"] === "Optional"
    ) {
      message.error("No questions added!");
      return;
    }
    setIsLoading(true);
    setIsDrafted(true);
    message.loading("Saving!", [1]);
    //API to save the survey details
    //returns a surveyID alloted to this survey
    request
      .post(
        APP_CONSTANTS.API_URL +
          "/CreateNewSurvey?ClientId=" +
          storeData[0]["clientID"] +
          "&lastSavedStep=" +
          lastSavedStep,
        storeData
      )
      .then((response) => {
        // console.log("Data sent to DB successfully", response);
        //update surveyid in store
        for (let i = 0; i < storeData.length; i++) {
          storeData[i]["surveyID"] = response.data.result[0];
        }
        // console.log("StoreData after updating surveyID", storeData);
        CurrentSurveyStore.addToCurrentSurveysFromSurveyFields(storeData[0]);
        SurveyStore.deleteStoreData();
        SurveyStore.addSurveyFieldsData(storeData[0]);
        SurveyStore.addQuestionData(storeData);
        setIsLoading(false);
        if (response.data.status === 200) message.success("Saved!");
        else message.error("Error!");
        if (navigateToNext) {
          //if button was 'Respondent Selection' navigate to next screen page
          SurveyStore.increaseNavigationCounter();
          navigate("/respondentselection");
        }
      })
      .catch((err) => {
        message.error("Error!");
        // console.log(err);
        setIsLoading(false);
      });
  }

  // if page is refreshed navigate to homepage
  useEffect(() => {
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  function DownloadDocument(fileName) {
    //specify path for file to download
    message.loading("Downloading", [1]);
    //API to download a document, given a proper filepath
    //returns an object, which needs to downloaded in desired format
    request
      .get(
        APP_CONSTANTS.API_URL +
          "/Home/DownloadFromS3?filePath=" +
          storeData[0]["clientID"] +
          "/" +
          storeData[0]["surveyID"] +
          "/" +
          fileName,
        {
          method: "GET",
          responseType: "blob",
        }
      )
      .then((res) => {
        // console.log("From s3", res);
        //open the document
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Error!");
        // console.log({ err });
      });
  }

  return (
    <div className="h-full">
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "87.5vh" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : (
        <div className="pb-2" style={{ minHeight: "87.5vh" }}>
          <Stepper current={2} />
          <div style={{ minHeight: "66.5vh" }} className="px-24 pr-28">
            {storeData[0]["quesText"] === "" ||
            storeData[0]["quesTitle"] === "Optional" ? (
              <div className=" mt-6">
                <div
                  style={{ minHeight: "4rem" }}
                  className="bg-white"
                  // id="reviewquestioncard"
                >
                  <div
                    className="pt-3 pl-4 text-lg"
                    style={{
                      height: "3rem",
                      backgroundColor: "#0a3859",
                      color: "whitesmoke",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                    }}
                  >
                    <i className="fa-solid fa-arrow-down mr-2"></i>
                    Start Here
                  </div>
                  <div
                    style={{
                      borderRadius: 5,
                      padding: "1rem",
                    }}
                    id="reviewquestioncard"
                    className="mb-4"
                  >
                    Download and read these documents prior to taking the
                    survey.
                    <div className="mt-2">
                      {storeData[0]["docs_link"] === null ||
                      storeData[0]["docs_link"] === undefined
                        ? ""
                        : storeData[0]["docs_link"].map((item) => {
                            return (
                              <p
                                className="my-1"
                                style={{
                                  cursor: "pointer",
                                  color: "teal",
                                }}
                                onClick={() => {
                                  DownloadDocument(item);
                                }}
                              >
                                {item}
                              </p>
                            );
                          })}
                    </div>
                  </div>
                  {/* <div
                    className="pt-3 pl-4 text-lg"
                    style={{
                      height: "3rem",
                      backgroundColor: "#0a3859",
                      color: "whitesmoke",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                    }}
                  >
                    <i className="fa-solid fa-arrow-down mr-2"></i>
                    Start Here
                  </div>
                  <div
                    style={{
                      borderRadius: 5,
                      padding: "1rem",
                    }}
                  >
                    Download and read these documents prior to taking the
                    survey.
                    <div className="mt-2 text-gray-400">
                      {storeData[0]["docs_link"].map((item, index) => {
                        return (
                          <p
                            key={index}
                            className="my-1 "
                            style={{ cursor: "pointer", color: "#27a6a4" }}
                          >
                            onClick=
                            {() => {
                              DownloadDocument(item);
                            }}
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
                <div className="text-black text-center pt-24 text-2xl border-gray-300 border-2 h-96">
                  <div className="mt-4">
                    <FileAddOutlined
                      style={{ fontSize: "5rem", color: "#27a6a4" }}
                    />
                  </div>
                  <br />
                  Add questions to review survey summary!
                </div>
              </div>
            ) : (
              <div className=" mt-6">
                <div className="">
                  <div
                    style={{ minHeight: "4rem" }}
                    className="bg-white"
                    // id="reviewquestioncard"
                  >
                    <div
                      className="pt-3 pl-4 text-lg"
                      style={{
                        height: "3rem",
                        backgroundColor: "#0a3859",
                        color: "whitesmoke",
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    >
                      <i className="fa-solid fa-arrow-down mr-2"></i>
                      Start Here
                    </div>
                    <div
                      style={{
                        borderRadius: 5,
                        padding: "1rem",
                      }}
                      id="reviewquestioncard"
                      className="mb-4"
                    >
                      Download and read these documents prior to taking the
                      survey.
                      <div className="mt-2">
                        {storeData[0]["docs_link"] === null ||
                        storeData[0]["docs_link"] === undefined
                          ? ""
                          : storeData[0]["docs_link"].map((item) => {
                              return (
                                <p
                                  className="my-1"
                                  style={{
                                    cursor: "pointer",
                                    color: "teal",
                                  }}
                                  onClick={() => {
                                    DownloadDocument(item);
                                  }}
                                >
                                  {item}
                                </p>
                              );
                            })}
                      </div>
                    </div>
                    {/* <div
                      className="pt-3 pl-4 text-lg"
                      style={{
                        height: "3rem",
                        backgroundColor: "#0a3859",
                        color: "whitesmoke",
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    >
                      <i className="fa-solid fa-arrow-down mr-2"></i>
                      Start Here
                    </div>
                    <div
                      style={{
                        borderRadius: 5,
                        padding: "1rem",
                      }}
                    >
                      Download and read these documents prior to taking the
                      survey.
                      <div className="mt-2 text-gray-400">
                        {storeData[0]["docs_link"].map((item, index) => {
                          return (
                            <p
                              key={index}
                              className="my-1 "
                              style={{
                                cursor: "pointer",
                                color: "#27a6a4",
                              }}
                              onClick={() => {
                                DownloadDocument(item);
                              }}
                            >
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-2">
                    <p className="text-red-500">
                      Questions marked with * cannot be left blank!
                    </p>
                  </div>
                </div>
                <div className="" ref={ref} id="divToPrint">
                  <div id="description" className="mb-2">
                    <p className="text-gray text-sm">
                      {storeData[0]["surveyDescription"]}
                    </p>
                  </div>
                  {storeData.map((item, i) => {
                    let renderDivName = "questionCard" + String(i);
                    if (item["isUserCreated"]) {
                      return (
                        <div
                          key={i}
                          className={`bg-white ${
                            item["is_highlight"] ? "border-2" : ""
                          } `}
                          id={renderDivName}
                          style={{
                            borderRadius: 5,
                            borderColor: "#0a3859",
                            alignItems: "center",
                            marginBottom: "1rem",
                            padding: "1rem",
                            marginTop: "1rem",
                          }}
                        >
                          <div className="mt-2 pl-3 flex">
                            <div className="flex">
                              {item["quesTitle"] === "Optional" ? (
                                ""
                              ) : (
                                <p className="text-red-500">*&nbsp;</p>
                              )}
                              {item["quesTitle"] === "Optional" ? (
                                ""
                              ) : item["is_highlight"] ? (
                                <p
                                  className="mr-2 font-semibold"
                                  style={{ color: "#0a3859" }}
                                >
                                  {i + 1}.
                                </p>
                              ) : (
                                <p className="mr-2">{i + 1}.</p>
                              )}
                            </div>
                            {item["is_highlight"] ? (
                              <p
                                className="font-semibold"
                                style={{ color: "#0a3859" }}
                              >
                                {item["quesText"]}
                              </p>
                            ) : (
                              <p>{item["quesText"]}</p>
                            )}
                          </div>
                          <hr></hr>
                          <div className="mt-2">
                            {item["quesType"] === 1 ? (
                              <div className="px-6">
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                ></Input>
                              </div>
                            ) : item["quesType"] === 2 ? (
                              <div className="flex">
                                {preprocess(item["customResponses"])}
                              </div>
                            ) : item["quesType"] === 3 ? (
                              <div className="px-6">
                                {Object.keys(item["customResponses"]).map(
                                  (option, i) => {
                                    return (
                                      <div className=" my-1 flex">
                                        <Radio
                                          defaultChecked={false}
                                          disabled={true}
                                        >
                                          {item["customResponses"][option]}
                                        </Radio>
                                      </div>
                                    );
                                  }
                                )}
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                />
                              </div>
                            ) : item["quesType"] === 5 ? (
                              Object.keys(item["customResponses"]).map(
                                (option, i) => {
                                  return (
                                    <div className="pl-6 my-1 flex">
                                      <Radio
                                        defaultChecked={false}
                                        disabled={true}
                                      >
                                        {item["customResponses"][option]}
                                      </Radio>
                                    </div>
                                  );
                                }
                              )
                            ) : item["quesType"] === 6 ? (
                              Object.keys(item["customResponses"]).map(
                                (option, i) => {
                                  return (
                                    <div className="pl-6 my-1 flex">
                                      <Checkbox disabled={true}>
                                        {item["customResponses"][option]}
                                      </Checkbox>
                                    </div>
                                  );
                                }
                              )
                            ) : item["quesType"] === 7 ? (
                              <div className="px-6">
                                {Object.keys(item["customResponses"]).map(
                                  (option, i) => {
                                    return (
                                      <div className="my-1 flex">
                                        <Checkbox disabled={true}>
                                          {item["customResponses"][option]}
                                        </Checkbox>
                                      </div>
                                    );
                                  }
                                )}
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                />
                              </div>
                            ) : (
                              Object.keys(item["customResponses"]).map(
                                (option, i) => {
                                  let ranks = [];
                                  for (
                                    let i = 0;
                                    i < item["responseLimit"];
                                    i++
                                  ) {
                                    ranks.push(i + 1);
                                  }
                                  return (
                                    <div className="pl-6 my-1 flex">
                                      <Select style={{ width: "4rem" }}>
                                        {ranks.map((rank) => {
                                          return (
                                            <Option disabled value={rank}>
                                              {rank}
                                            </Option>
                                          );
                                        })}
                                      </Select>

                                      <p className="ml-2 mt-1 disableRanks">
                                        {item["customResponses"][option]}
                                      </p>
                                    </div>
                                  );
                                }
                              )
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={i}
                          className={`bg-white ${
                            item["is_highlight"] ? "border-2" : ""
                          } `}
                          id={renderDivName}
                          style={{
                            borderRadius: 5,
                            alignItems: "center",
                            borderColor: "#0a3859",
                            marginBottom: "1rem",
                            padding: "1rem",
                            marginTop: "1rem",
                          }}
                        >
                          <div className="mt-2 pl-3 flex">
                            {item["quesTitle"] === "Optional" ? (
                              ""
                            ) : (
                              <p className="text-red-500">*&nbsp;</p>
                            )}
                            {item["quesTitle"] === "Optional" ? (
                              ""
                            ) : item["is_highlight"] ? (
                              <p
                                className="mr-2 font-semibold"
                                style={{ color: "#0a3859" }}
                              >
                                {i + 1}.
                              </p>
                            ) : (
                              <p className="mr-2">{i + 1}.</p>
                            )}
                            {item["is_highlight"] ? (
                              <p
                                className="font-semibold"
                                style={{ color: "#0a3859" }}
                              >
                                {item["quesText"]}
                              </p>
                            ) : (
                              <p>{item["quesText"]}</p>
                            )}
                          </div>
                          <hr></hr>
                          <div className="mt-2">
                            {item["quesType"] === 1 ? (
                              <div className="px-6">
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                ></Input>
                              </div>
                            ) : item["quesType"] === 2 ? (
                              <div className="flex px-6">
                                {Object.keys(item["responseOptions"]).map(
                                  (option, index) => {
                                    return (
                                      <div key={index} className="my-1 flex">
                                        <Radio
                                          defaultChecked={false}
                                          disabled={true}
                                        >
                                          {item["responseOptions"][option]}
                                        </Radio>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : item["quesType"] === 3 ? (
                              <div className="px-6">
                                {Object.keys(item["responseOptions"]).map(
                                  (option, index) => {
                                    return (
                                      <div key={index} className="my-1 flex">
                                        <Radio
                                          defaultChecked={false}
                                          disabled={true}
                                        >
                                          {item["responseOptions"][option]}
                                        </Radio>
                                      </div>
                                    );
                                  }
                                )}
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                />
                              </div>
                            ) : item["quesType"] === 5 ? (
                              Object.keys(item["responseOptions"]).map(
                                (option, index) => {
                                  return (
                                    <div key={index} className="pl-6 my-1 flex">
                                      <Radio
                                        defaultChecked={false}
                                        disabled={true}
                                      >
                                        {item["responseOptions"][option]}
                                      </Radio>
                                    </div>
                                  );
                                }
                              )
                            ) : item["quesType"] === 6 ? (
                              Object.keys(item["responseOptions"]).map(
                                (option, index) => {
                                  return (
                                    <div key={index} className="pl-6 my-1 flex">
                                      <Checkbox disabled={true}>
                                        {item["responseOptions"][option]}
                                      </Checkbox>
                                    </div>
                                  );
                                }
                              )
                            ) : item["quesType"] === 7 ? (
                              <div className="px-6">
                                {Object.keys(item["responseOptions"]).map(
                                  (option, index) => {
                                    return (
                                      <div key={index} className="my-1 flex">
                                        <Checkbox disabled={true}>
                                          {item["responseOptions"][option]}
                                        </Checkbox>
                                      </div>
                                    );
                                  }
                                )}
                                <Input
                                  disabled={true}
                                  value="Enter Your Comments Here"
                                />
                              </div>
                            ) : (
                              Object.keys(item["responseOptions"]).map(
                                (option, index) => {
                                  let ranks = [];
                                  for (
                                    let i = 0;
                                    i < item["responseLimit"];
                                    i++
                                  ) {
                                    ranks.push(i + 1);
                                  }

                                  return (
                                    <div key={index} className="pl-6 my-1 flex">
                                      <Select style={{ width: "4rem" }}>
                                        {ranks.map((rank, rankIndex) => {
                                          return (
                                            <Option
                                              key={rankIndex}
                                              disabled
                                              // className="w-5"
                                              value={rank}
                                            >
                                              {rank}
                                            </Option>
                                          );
                                        })}
                                      </Select>

                                      <p className="ml-2 mt-1 disableRanks">
                                        {item["responseOptions"][option]}
                                      </p>
                                    </div>
                                  );
                                }
                              )
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </div>
          <div id="disclaimer">
            <Checkbox
              onClick={() =>
                setIsCheckboxDisabled(isCheckboxDisabled ? false : true)
              }
              checked={!isCheckboxDisabled}
            >
              By checking this box you attest that you have completed the
              necessary compliance steps for market research and have obtained
              all the needed approvals to add new questions (if any) outside of
              already approved Question Bank.
            </Checkbox>
          </div>
          <div id="footerOne">
            <Card size="small">
              <Space>
                <Tooltip
                  title={`Proceeding to "Respondent Selection" will automatically save your current progress`}
                >
                  <Button
                    id="confirmSubmission"
                    type="primary"
                    onClick={RespondentSelection}
                    disabled={isCheckboxDisabled}
                  >
                    Respondent Selection
                  </Button>
                </Tooltip>
                <Button onClick={SaveDraftHandler}>Save as Draft</Button>
                {storeData[0]["quesTitle"] === "Optional" ? (
                  <Button disabled>Save as PDF</Button>
                ) : (
                  <ReactToPrint
                    documentTitle={storeData[0]["surveyName"]}
                    onBeforeGetContent={async () => {
                      message.loading("Loading!");
                      let heightSoFar =
                        document.getElementById("description").clientHeight;
                      document
                        .getElementById("divToPrint")
                        .classList.remove("w-4/5");
                      document
                        .getElementById("divToPrint")
                        .classList.add("px-20");
                      document
                        .getElementById("divToPrint")
                        .classList.add("w-full");
                      document
                        .getElementById("divToPrint")
                        .classList.add("pt-8");
                      document
                        .getElementById("divToPrint")
                        .classList.add("pb-6");

                      for (let i = 0; i < storeData.length; i++) {
                        let divName = "questionCard" + String(i);
                        heightSoFar +=
                          document.getElementById(divName).clientHeight;
                        if (heightSoFar > 940) {
                          document.getElementById(
                            divName
                          ).style.pageBreakBefore = "always";
                          heightSoFar =
                            document.getElementById(divName).clientHeight;
                        }
                      }
                      await sleep(1800);
                    }}
                    onAfterPrint={() => {
                      document
                        .getElementById("divToPrint")
                        .classList.remove("px-20");
                      document
                        .getElementById("divToPrint")
                        .classList.remove("pt-8");
                      document
                        .getElementById("divToPrint")
                        .classList.remove("pb-6");
                      document
                        .getElementById("divToPrint")
                        .classList.remove("w-full");
                      document
                        .getElementById("divToPrint")
                        .classList.add("w-4/5");
                    }}
                    trigger={() => (
                      <Button onClick={() => message.loading("Loading!")}>
                        Save as PDF
                      </Button>
                    )}
                    content={() => ref.current}
                    bodyClass="bg-gray-50"
                  />
                )}
                <Button onClick={showModalPrevious}>Previous</Button>
                <Button type="danger" onClick={showModalCancel}>
                  Cancel
                </Button>
              </Space>
            </Card>
          </div>
        </div>
      )}
      <Modal
        title="Cancel Survey"
        visible={isModalVisibleCancel}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {!SurveyStore.getIsEdited() ? (
          <p>Do you want to delete this survey and all its related data?</p>
        ) : (
          <p>Are you sure? Changes made won't be applied!</p>
        )} */}
        <p>Are you sure? Changes made won't be applied!</p>
      </Modal>
      <Modal
        title="Go to Previous Screen"
        visible={isModalVisiblePrevious}
        onOk={handleOkPrevious}
        onCancel={handleCancel}
      >
        <p>Make sure you have saved you data before going back!</p>
      </Modal>
    </div>
  );
}

export default ReviewSurvey;
