import NavBar from "./component/NavBar";
import NavBarPayer from "./component/NavBarPayer";
import "antd/dist/antd.css";
import CurrentSurveysTable from "./Screens/CurrentSurvey/CurrentSurveysTable";
import SurveySummary from "./Screens/CurrentSurvey/SurveySummary";
import CreateSurveyQuestions from "./Screens/CreateSurvey/CreateSurveyQuestions";
import CreateSurveyFields from "./Screens/CreateSurvey/CreateSurveyFields";
import { Route, Routes, Navigate } from "react-router-dom";
import SurveyStore from "./Store/SurveyStore";
import QuestionBankStore from "./Store/QuestionBankStore";
import ReviewSurvey from "./Screens/CreateSurvey/ReviewSurvey";
import RespondentSelection from "./Screens/CreateSurvey/RespondentSelection";
import PublishSurvey from "./Screens/CreateSurvey/PublishSurvey";
import ThankYouRejected from "./Screens/PayerScreens/ThankYouRejected";
import ThankYouCompleted from "./Screens/PayerScreens/ThankYouCompleted";
import Login from "./Screens/account/login/Login";
import PayerStatus from "./Screens/PayerScreens/PayerStatus";
import PayerSurvey from "./Screens/PayerScreens/PayerSurvey";
import MainFooter from "./component/MainFooter";
import CurrentSurveyStore from "./Store/CurrentSurveyStore";
import PayerStatusStore from "./Store/PayerStatusStore";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./App.css";
import { message } from "antd";
import ClosedSurvey from "./Screens/PayerScreens/ClosedSurvey";
import Admin from "./Screens/admin/Admin";
import UnAuthUser from "./Screens/admin/UnAuthUser";
import axios from "axios";
import { APP_CONSTANTS } from "./Utils/Constant";
import { useNavigate } from "react-router-dom";
import Layout from "./Screens/account/login/Layout";
// import { useHistory, useLocation } from "react-router-dom";

export const request = axios.create({
  withCredentials: true,
});
const surveyStore = new SurveyStore(); //maintains survey related data for each survey
const questionBankStore = new QuestionBankStore(); //maintains the question bank
const currentSurveyStore = new CurrentSurveyStore(); //maintains the records of surveys created by a user
const payerStatusStore = new PayerStatusStore(); //maintains survey related data for each payer like payerStatus, payerId, etc
  
export default function App() {
  const [onLoginPage, setOnLoginPage] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [onPayerScreen, setOnPayerScreen] = useState(false);
  const navigate = useNavigate();
  request.interceptors.request.use((config) => reqConfig(config));
  request.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
  const reqConfig = (config) => {
    // // console.log("Checking config for Auth", config);
    return config;
  };
  const responseHandler = (response) => {
    // if (response.status === 200)
    //   window.location.href = "https://www.google.com/";
    return response;
  };
  const errorHandler = (error) => {
    if (error.response.status === 401)
      window.location.href =
        "https://idm.zsservices.com/ValueAccess/IdentityManager/app/Web/login.aspx?ReturnUrl=https://payerconnect.zsservices.com/";
    return Promise.reject(error);
  };
  useEffect(() => {
    if (
      String(window.location.pathname).includes("payer") ||
      String(window.location.pathname).includes("thankyou") ||
      String(window.location.pathname).includes("closedsurvey")
    ) {
      setOnPayerScreen(true);
      // console.log("On Payer Screen");
      let surveyLink = String(window.location);
      // console.log("Surveylink", surveyLink);
      sessionStorage.setItem("OriginalsurveyLink", surveyLink);
      
    }
    
    //API to get clientId corresponding to the provided username
    request
      .get(APP_CONSTANTS.API_URL + "/AuthenticateLogin")
      .then((response) => {
        if (response.data.status === 200) {
          // console.log("validated");
          // console.log("response.data.result", response.data.result);
          surveyStore.setClientId(response.data.result["clientId"]);
          surveyStore.setTeamName(response.data.result["team_name"]);
          //update session storage with client id and isAdmin
          sessionStorage.setItem(
            "appInfo",
            JSON.stringify({
              clientId: surveyStore.getClientId(),
              showNavFooter: true,
              isAdmin: response.data.result["isAdmin"],
              creditsBalance: response.data.result["creditsBalance"],
              clientName: response.data.result["clientName"],
              username: response.data.result["username"],
              team_name: response.data.result["team_name"],
              isClient: response.data.result["isClient"],
              isPayer: response.data.result["isPayer"],
            })
          );
          setAppData();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        // message.error("Invalid Credentials!!");
        // console.log(err);
        // setIsLoading(false);
      });
      
      
    // setIsLoading(false);
  }, []);

  function setAppData(){
    let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
      // console.log(appInfo);
    
    if (appInfo !== null) {
      if (
        appInfo["clientId"] !== null ||
        appInfo["clientId"] !== undefined ||
        appInfo["clientId"] !== 0
      ) {
        surveyStore.setClientId(appInfo["clientId"]);
        surveyStore.setTeamName(appInfo["team_name"]);

        setOnLoginPage(true);
      }
    } else {
      setShowLogin(true);
    }
  }
  if (isLoading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "89.6vh" }}
      >
        <ScaleLoader
          color="teal"
          height={60}
          width={6}
          radius={18}
          margin={2}
        />
      </div>
    )
  }

return (
    <div>
      {JSON.parse(sessionStorage.getItem("appInfo"))? JSON.parse(sessionStorage.getItem("appInfo"))["isAdmin"] || JSON.parse(sessionStorage.getItem("appInfo"))["isClient"] ?
        (<NavBar
          //passing the required stores and states as props
          SurveyStore={surveyStore}
          /> )
      :
      JSON.parse(sessionStorage.getItem("appInfo"))["isPayer"] ?
        (
        <NavBarPayer />  ) : ""
      : ""
     } 
   
      <div style={{ height: "100%", backgroundColor: "whitesmoke" }}>
      <Routes>
            <Route
              path="/"
              //route to layout page
              element={<Layout surveyStore={surveyStore} />} exact
            />
            
            <Route
              path="/admin"
              //route to admin page
              element={<Admin SurveyStore={surveyStore} />}
            />
            <Route
              path="/currentsurveys"
              //route to the dashboard page, where all surveys are displayed
              element={
                <CurrentSurveysTable
                  //passing the required stores as props
                  CurrentSurveyStore={currentSurveyStore}
                  SurveyStore={surveyStore}
                />
              }
            />
            <Route
              path="/createsurvey"
              //route to create new survey page, where details like survey name and description are captured
              element={
                <CreateSurveyFields
                  //passing the required stores as props
                  CurrentSurveyStore={currentSurveyStore}
                  SurveyStore={surveyStore}
                />
              }
            />
            <Route
              path="/createsurveyquestions"
              //route to survey questions page, where user can add questions to the survey
              element={
                <CreateSurveyQuestions
                  //passing the required stores as props
                  QuestionBankStore={questionBankStore}
                  SurveyStore={surveyStore}
                  CurrentSurveyStore={currentSurveyStore}
                />
              }
            />
            <Route
              path="/reviewsurvey"
              //route to review survey page
              element={
                <ReviewSurvey
                  //passing the required stores as props
                  SurveyStore={surveyStore}
                  CurrentSurveyStore={currentSurveyStore}
                />
              }
            />
            <Route
              path="/respondentselection"
              //route to respondent selection page, from where payers/respondents can be selected
              element={
                <RespondentSelection
                  //passing the required stores as props
                  SurveyStore={surveyStore}
                  CurrentSurveyStore={currentSurveyStore}
                />
              }
            />
            <Route
              path="/publishsurvey"
              //route to the page which is to be displayed when user publishes a survey
              element={
                <PublishSurvey
                  //passing the required stores as props
                  SurveyStore={surveyStore}
                />
              }
            />
            <Route
              path="/payerstatus"
              //route to the payer status screen, where the payer updates its status (accept/reject)
              element={
                <PayerStatus
                  //passing the required stores as props
                  PayerStatusStore={payerStatusStore}
                />
              }
            />
            <Route
              path="/thankyour"
              //route to thank you rejected screen, to be displayed when payer rejects the survey
              element={
                <ThankYouRejected
                  //passing the required stores as props
                  PayerStatusStore={payerStatusStore}
                />
              }
            />
            <Route
              path="/thankyouc"
              //route to thank you completed screen, to be displayed when payer completes the survey
              element={
                <ThankYouCompleted //passing the required stores as props
                  PayerStatusStore={payerStatusStore}
                />
              }
            />
            <Route
              path="/closedsurvey"
              //route to closed survey screen, to be displayed when the survey is completed
              element={<ClosedSurvey PayerStatusStore={payerStatusStore} />}
            />
            <Route
              path="/payersurvey"
              //route to payer survey screen, where the survey would be displayed to the payer
              element={
                <PayerSurvey
                  //passing the required stores as props
                  PayerStatusStore={payerStatusStore}
                />
              }
            />
            <Route
              path="/surveysummary"
              //route to survey summary screen, which contains the charts and responses for a survey
              element={
                <SurveySummary
                  //passing the required stores as props
                  CurrentSurveyStore={currentSurveyStore}
                  SurveyStore={surveyStore}
                />
              }
            />
            <Route
              path="/invalid"
              //route to unauthorise access to admin page
              element={<UnAuthUser SurveyStore={surveyStore} />}
            />
            <Route
              path="*"
              //route to login page is url is not among the defined routes
              element={
                <Login
                  setOnLoginPage={setOnLoginPage}
                  SurveyStore={surveyStore}
                />
              }
            />
      </Routes>
      
      </div>  
      <div>
        <MainFooter/> 
      </div>
    </div>
);             
   
}
