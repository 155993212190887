import { action, toJS, computed, makeObservable, observable } from "mobx";

export class CurrentSurveyStore {
  //object to store all surveys created by a user
  CurrentSurveyData = [];

  constructor() {
    makeObservable(this, {
      CurrentSurveyData: observable,
      addCurrentSurveyData: action,
      getCurrentSurveyData: action,
      deleteSurvey: action,
      addToCurrentSurveysFromSurveyFields: action,
    });
  }

  addCurrentSurveyData(data) {
    //function to add surveys to store
    this.CurrentSurveyData = data;
  }

  addToCurrentSurveysFromSurveyFields(data) {
    //function to add survey data to current survey store when user saves a survey
    let tempData = {
      surveyId: data["surveyID"],
      surveyName: data["surveyName"],
      totalApproached: 0,
      totalRejected: 0,
      totalAccepted: 0,
      completedSurvey: 0,
      pendingSurvey: 0,
      surveyStatus: ["Unpublished"],
      publishedAt: "",
      deadline: "",
      createdBy: data["clientID"],
      lastUpdated: "",
      isDeleted: false,
      deletedBy: null,
    };
    if (this.CurrentSurveyData !== null) {
      for (let i = 0; i < this.CurrentSurveyData.length; i++) {
        if (this.CurrentSurveyData[i]["surveyId"] === tempData["surveyId"]) {
          // console.log("found exisiting survey id");
          this.CurrentSurveyData[i] = tempData;
          return;
        }
      }
    }
    if (this.CurrentSurveyData === null) {
      this.CurrentSurveyData = [tempData];
    } else this.CurrentSurveyData.push(tempData);
    // console.log("adding new item", toJS(this.CurrentSurveyData));
  }

  getCurrentSurveyData() {
    //function to get surveys from store
    return toJS(this.CurrentSurveyData);
  }

  deleteSurvey(surveyId) {
    //function to delete surveys from store
    for (let i = 0; i < this.CurrentSurveyData.length; i++) {
      if (this.CurrentSurveyData[i]["surveyId"] === surveyId)
        this.CurrentSurveyData.splice(i, 1);
    }
  }
}

export default CurrentSurveyStore;
