import { Form, Input, Button, Layout, Checkbox, message } from "antd";
import React, { useEffect, useState } from "react";
import "./login.css";
import ZSLogo from "../../../zslogo.png";
import payerconnect from "../../../payerconnect.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { APP_CONSTANTS } from "../../../Utils/Constant";
import { request } from "../../../App";

function Login({ SurveyStore, setOnLoginPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setloggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //check if user is already logged in
    // let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
    // if (appInfo !== null) {
    //   request
    //     .get(APP_CONSTANTS.API_URL + "/AuthenticateLogin")
    //     .then((response) => {
    //       // console.log("Checking for username", appInfo["username"]);
    //       if (response.data.status === 200) {
    //         // console.log("validated");
    //         // console.log("response.data.result", response.data.result);
    //         SurveyStore.setClientId(response.data.result["clientId"]);
    //         SurveyStore.setTeamName(response.data.result["team_name"]);
    //         // console.log(response.data.result["team_name"]);
    //         //update session storage with client id and isAdmin
    //         sessionStorage.setItem(
    //           "appInfo",
    //           JSON.stringify({
    //             clientId: SurveyStore.getClientId(),
    //             showNavFooter: true,
    //             isAdmin: response.data.result["isAdmin"],
    //             creditsBalance: response.data.result["creditsBalance"],
    //             clientName: response.data.result["clientName"],
    //             username: appInfo["username"],
    //             team_name: SurveyStore.getTeamName(),
    //             isClient: response.data.result["isClient"],
    //             isPayer: response.data.result["isPayer"],
    //           })
    //         );
    //         // console.log(response.data.result["team_name"]);
    //         // console.log(
    //           SurveyStore.getTeamName(),
    //           sessionStorage.getItem("appInfo")
    //         );
    //         setOnLoginPage(true);
    //         // navigate("/currentsurveys");
    //         if (
    //           response.data.result["isPayer"] === true &&
    //           response.data.result["isClient"] === false &&
    //           response.data.result["isAdmin"] === false
    //         ) {
    //           if (
    //             sessionStorage.getItem("OriginalsurveyLink") !== null &&
    //             sessionStorage.getItem("OriginalsurveyLink") !== "" &&
    //             sessionStorage.getItem("OriginalsurveyLink") !== undefined
    //           ) {
    //             window.open(
    //               sessionStorage.getItem("OriginalsurveyLink"),
    //               "_self"
    //             );
    //           } else {
    //             navigate("/payerstatus");
    //           }
    //         } else if (
    //           response.data.result["isClient"] === true ||
    //           response.data.result["isAdmin"] === true
    //         ) {
    //           navigate("/currentsurveys");
    //         }
    //         setIsLoading(false);
    //         setloggedIn(true);
    //       } else {
    //         // message.error("Invalid Credentials!");
    //         setIsLoading(false);
    //       }
    //     })
    //     .catch((err) => {
    //       // message.error("Invalid Credentials!!");
    //       // console.log(err);
    //       setIsLoading(false);
    //     });

    //   // navigate("/currentsurveys");
    // }
    // setIsLoading(false);
    onFinish("ABC");
    // navigate("/currentsurveys");
  }, []);

  function onFinish(values) {
    setIsLoading(true);
    // console.log(values);
    if (1 || values) {
      //API to get clientId corresponding to the provided username
      request
        .get(APP_CONSTANTS.API_URL + "/AuthenticateLogin")
        .then((response) => {
          // console.log("Checking for username", username);
          if (response.data.status === 200) {
            // console.log("validated");
            // console.log("response.data.result", response.data.result);
            SurveyStore.setClientId(response.data.result["clientId"]);
            SurveyStore.setTeamName(response.data.result["team_name"]);
            //update session storage with client id and isAdmin
            sessionStorage.setItem(
              "appInfo",
              JSON.stringify({
                clientId: SurveyStore.getClientId(),
                showNavFooter: true,
                isAdmin: response.data.result["isAdmin"],
                creditsBalance: response.data.result["creditsBalance"],
                clientName: response.data.result["clientName"],
                username: response.data.result["username"],
                team_name: response.data.result["team_name"],
                isClient: response.data.result["isClient"],
                isPayer: response.data.result["isPayer"],
              })
            );
            // console.log(response.data.result["team_name"]);
            console.log(
              SurveyStore.getTeamName(),
              sessionStorage.getItem("appInfo")
            );
            setOnLoginPage(true);
            // navigate("/currentsurveys");
            if (
              response.data.result["isPayer"] === true &&
              response.data.result["isClient"] === false &&
              response.data.result["isAdmin"] === false
            ) {
              if (
                sessionStorage.getItem("OriginalsurveyLink") !== null &&
                sessionStorage.getItem("OriginalsurveyLink") !== "" &&
                sessionStorage.getItem("OriginalsurveyLink") !== undefined
              ) {
                window.open(
                  sessionStorage.getItem("OriginalsurveyLink"),
                  "_self"
                );
              }
              // else {
              //   navigate("/payerstatus");
              // }
            } else if (
              response.data.result["isClient"] === true ||
              response.data.result["isAdmin"] === true
            ) {
              navigate("/currentsurveys");
            }
            // setIsLoading(false);
            setloggedIn(true);
          } else {
            // message.error("Invalid Credentials!");
            // setIsLoading(false)
          }
        })
        .catch((err) => {
          // message.error("Invalid Credentials!!");
          // console.log(err);
          // setIsLoading(false);
        });
    } else {
      // message.error("Invalid Credentials!");
      // setIsLoading(false);
    }
  }
  return (
    <div style={{ minHeight: "89vh" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "89.6vh" }}
        >
          <ScaleLoader
            color="teal"
            height={60}
            width={6}
            radius={18}
            margin={2}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Login;
