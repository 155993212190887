import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import { APP_CONSTANTS } from "../../Utils/Constant";
import { useNavigate } from "react-router-dom";
import { request } from "../../App";

//component which allows admin to add another user in its environment
function AddUser({ SurveyStore }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const { Option } = Select;
  const [name, setName] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      companyName: String(
        JSON.parse(sessionStorage.getItem("appInfo"))["clientName"]
      ),
    });
    if (
      JSON.parse(sessionStorage.getItem("appInfo")) === null ||
      JSON.parse(sessionStorage.getItem("appInfo")) === undefined
    ) {
      navigate("/"); //usr is not logged in
    }
  }, []);

  const GetPayerTeams = () => {
    let ResultArray = [];
    request
      .get(APP_CONSTANTS.API_URL + "/GetPayerTeams")
      .then((res) => {
        // console.log("Response: from api", res);
        const result = res.data.result;
        ResultArray = result;
        // console.log(ResultArray);
        setDropdownData(ResultArray);
      })
      .catch((err) => {
        // console.log({ err });
      });
    return ResultArray;
  };

  useEffect(() => {
    GetPayerTeams();
  }, []);

  function onFinish(values) {
    //send new user details to DB
    // console.log("values", values);
    let isAdmin = false;
    if (values["isAdmin"] === "yes") isAdmin = true;
    let tempObject = [
      {
        addedBy: String(
          JSON.parse(sessionStorage.getItem("appInfo"))["clientId"]
        ),
        isActive: true,
        isDeleted: false,
        username: values["name"],
        companyName: String(values["companyName"]).toUpperCase(),
        userMailAddress: values["email"],
        isAdmin: isAdmin,
        teamName: values["teamName"],
        otherName: values["otherName"],
      },
    ];
    setIsLoading(true);
    // console.log("user details", tempObject);
    request
      .post(APP_CONSTANTS.API_URL + "/AddNewUser", tempObject)
      .then((response) => {
        // console.log("response", response);
        if (response.data.status === 200) {
          message.success("User Added!");
          form.resetFields();
          form.setFieldsValue({
            companyName: JSON.parse(sessionStorage.getItem("appInfo"))[
              "clientName"
            ],
          });
        } else message.error("Error!");
      })
      .catch((err) => {
        message.error("Error");
        // console.log(err);
      })
      .then((res) => {
        setIsLoading(false);
      });
    window.open(
      "https://idm.zsservices.com/ValueAccess/IdentityManager/app/Web/Admin/ManageUsers.aspx?top",
      "_blank",
      "noopener,noreferrer"
    );
  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "65.5vh" }}
        >
          <div>
            <div className="flex justify-center items-center">
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
            <p className="text-black font-semibold text-xl mt-4">
              Adding User. Please wait!
            </p>
          </div>
        </div>
      ) : (
        <Form
          name="userDetails"
          labelCol={{ span: 7 }}
          onFinish={onFinish}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          form={form}
        >
          <div className="pl-40 pr-60 mt-32">
            <Form.Item
              label="Name"
              name={"name"}
              id="surveyname"
              rules={[{ required: true, message: "Please Enter Name!" }]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>

            <Form.Item
              label="Company Name"
              name={"companyName"}
              rules={[
                { required: true, message: "Please Enter Company Name!" },
              ]}
            >
              {JSON.parse(sessionStorage.getItem("appInfo")) === null ? (
                ""
              ) : (
                <Input
                  defaultValue={
                    JSON.parse(sessionStorage.getItem("appInfo"))["clientName"]
                  }
                  disabled
                  placeholder="Enter Company Name"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Team Name"
              name={"teamName"}
              rules={[
                {
                  required: true,
                  message: "Please select Team Name!",
                },
              ]}
            >
              <Select onChange={(e) => setText(e)} value={text}>
                {dropdownData.map((val, i) => {
                  return <Option key={val}>{val}</Option>;
                })}
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
            {text === "other" && (
              <Form.Item
                label="Other Team Name"
                name={"otherName"}
                rules={[
                  {
                    required: true,
                    message: "Please select Team Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Team Name" />
              </Form.Item>
            )}
            <Form.Item
              label="E-Mail ID"
              name={"email"}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please Enter E-mail ID!",
                },
              ]}
            >
              <Input placeholder="Enter Email-ID" />
            </Form.Item>

            <Form.Item
              label="Will this user be an Admin?"
              name={"isAdmin"}
              rules={[
                {
                  required: true,
                  message: "Please select a response!",
                },
              ]}
            >
              <Select>
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="mx-96"
                style={{
                  width: "8rem",
                  // marginLeft: "1rem",
                  marginTop: "1rem",
                }}
                type="primary"
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
}

export default AddUser;
