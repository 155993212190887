import Stepper from "../../component/Stepper";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  InputNumber,
  Select,
  Form,
  Space,
  Button,
  message,
  Table,
  Tooltip,
  Card,
  Modal,
} from "antd";
import {
  FileSearchOutlined,
  CheckCircleFilled,
  CloseOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { request } from "../../App";

const { Option } = Select;

const getPayerSummary = async () => {
  let ResultArray = [];
  //API to get all Payer data across all countries
  await request
    .get(APP_CONSTANTS.API_URL + "/PayerSummary")
    .then((res) => {
      const result = res.data.result;
      ResultArray = result;
      // console.log(result);
    })
    .catch((err) => {
      // console.log({ err });
    });
  return ResultArray;
};

function RespondentSelection({ SurveyStore, CurrentSurveyStore }) {
  const storeData = SurveyStore.returnData();
  const [payerData, setPayerData] = useState([]);
  const [copyPayerData, setCopyPayerData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPublished, setIsPublished] = useState(false);
  const [payerRole, setPayerRole] = useState([]);
  const [payerType, setPayerType] = useState([]);
  const [poolData, setPoolData] = useState([]);
  const [renderPoolData, setRenderPoolData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [initialTableData, setInitialTableData] = useState([]);
  const [country, setCountry] = useState();
  const [check, setCheck] = useState(true);
  const [isModalVisiblePublish, setIsModalVisiblePublish] = useState(false);
  const [isModalVisibleCreditsLow, setIsModalVisibleCreditsLow] =
    useState(false);
  const [isModalVisiblePrevious, setIsModalVisiblePrevious] = useState(false);
  const [isModalVisibleCancel, setIsModalVisibleCancel] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [foundLessPayers, setFoundLessPayers] = useState(false);
  const [toPreprocessPayerData, setToPreprocessPayerData] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  const [button, setButton] = useState("Save");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  var maxRespondentsPerCountry = 3;
  if (country === "USA") {
    maxRespondentsPerCountry = 5;
  }
  const getRespondentIDs = async () => {
    let ResultArray = [];
    //API to get all Payer data to whom this survey has already been sent
    await request
      .get(
        APP_CONSTANTS.API_URL +
          "/PublishedPayerSummary?SurveyId=" +
          storeData[0]["surveyID"]
      )
      .then((res) => {
        const result = res.data.result;
        ResultArray = result;
        // console.log(result);
      })
      .catch((err) => {
        // console.log({ err });
      });
    return ResultArray;
  };

  function getCountries() {
    //get all countries from payer data
    let tempCountries = [];
    for (let i = 0; i < payerData.length; i++) {
      tempCountries.push(payerData[i]["country"]);
    }
    tempCountries = [...new Set(tempCountries)];
    setCountries(tempCountries);
  }

  useEffect(() => {
    // get payer summary
    window.scrollTo(0, 0);
    getPayerSummary().then((output) => {
      setIsLoading(true);
      setPayerData(output);
      setCopyPayerData(output);
      setToPreprocessPayerData(true);
    });
  }, []);

  useEffect(() => {
    if (
      storeData[0]["surveyID"] !== 0 &&
      storeData[0]["surveyID"] !== null &&
      storeData[0]["surveyID"] !== undefined
    ) {
      let tempRespondents = [...tableData];
      setIsLoading(true);
      getRespondentIDs()
        .then((output) => {
          //remove the payer to whom survey has already been sent from copyPayerData,
          // console.log("payerIDS", output);
          if (output !== null && output !== undefined) {
            let tempPayerData = [...copyPayerData];
            for (let i = 0; i < output.length; i++) {
              //if this payer has received the survey, remove it from payerList, from which payers are selected
              if (output[i]["isPayerPublished"] === true) {
                for (let j = 0; j < tempPayerData.length; j++) {
                  if (output[i]["payerID"] === tempPayerData[j]["payerID"]) {
                    tempPayerData.splice(j, 1);
                    break;
                  }
                }
              }
              //else add this payer to respondent table
              else {
                //get count of similar payers
                let count = 0;
                for (let j = 0; j < output.length; j++) {
                  if (
                    output[j]["country"] === output[i]["country"] &&
                    output[j]["payerRole"] === output[i]["payerRole"] &&
                    output[j]["payerType"] === output[i]["payerType"] &&
                    output[j]["isPayerPublished"] !== true
                  ) {
                    count++;
                  }
                }
                tempRespondents.push({
                  count: count,
                  country: output[i]["country"],
                  role: output[i]["payerRole"],
                  type: output[i]["payerType"],
                  key: tempRespondents.length,
                });
              }
            }
            //remove duplicates
            tempRespondents = tempRespondents.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.count === value.count &&
                    t.country === value.country &&
                    t.role === value.role &&
                    t.type === value.type
                )
            );
            setTableData(tempRespondents);
            setCopyPayerData([...tempPayerData]);
            //also update the table data (remove payers who have rejected)
            let tempInitialTableData = [];
            for (let i = 0; i < output.length; i++) {
              if (
                output[i]["surveyStatus"] !== "Rejected" &&
                output[i]["isPayerPublished"]
              ) {
                let count = 0;
                for (let j = 0; j < output.length; j++) {
                  if (
                    output[j]["country"] === output[i]["country"] &&
                    output[j]["payerRole"] === output[i]["payerRole"] &&
                    output[j]["payerType"] === output[i]["payerType"] &&
                    output[j]["surveyStatus"] !== "Rejected"
                  ) {
                    count++;
                  }
                }
                tempInitialTableData.push({
                  count: count,
                  country: output[i]["country"],
                  role: output[i]["payerRole"],
                  type: output[i]["payerType"],
                  key: tempInitialTableData.length,
                });
              }
            }
            //remove duplicates
            tempInitialTableData = tempInitialTableData.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.count === value.count &&
                    t.country === value.country &&
                    t.role === value.role &&
                    t.type === value.type
                )
            );
            setInitialTableData([...tempInitialTableData]);
          }
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
    }
  }, [toPreprocessPayerData]);

  useEffect(() => {
    // get countries
    getCountries();
  }, [payerData]);

  useEffect(() => {
    //update the data that will be displayed to the user
    setRenderPoolData(poolData);
  }, [poolData]);

  useEffect(() => {
    //update table data to render
    if (isDeleted) {
      let tempTable = [...tableData];
      for (let i = 0; i < tempTable.length; i++) {
        if (
          tempTable[i]["country"] === dataRecord["country"] &&
          tempTable[i]["role"] === dataRecord["role"] &&
          tempTable[i]["type"] === dataRecord["type"] &&
          tempTable[i]["count"] === dataRecord["count"]
        ) {
          tempTable.splice(i, 1);
        }
      }
      setIsDeleted(false);
      setTableData(tempTable);
    } else {
      if (poolData.length != 0) {
        let tempTable = [...tableData];
        //check if filtered payer already added to pool
        let flag = false;
        for (let i = 0; i < tempTable.length; i++) {
          if (
            tempTable[i]["country"] === poolData[0]["country"] &&
            tempTable[i]["type"] === poolData[0]["payerType"] &&
            tempTable[i]["role"] === poolData[0]["payerRole"]
          ) {
            tempTable[i]["count"] += poolData.length;
            flag = true;
            break;
          }
        }
        let tempTableObject = {
          count: poolData.length,
          country: poolData[0]["country"],
          role: poolData[0]["payerRole"],
          type: poolData[0]["payerType"],
          key: tempTable.length,
        };
        if (flag) {
          setTableData(tempTable);
          setPoolData([]);
        } else {
          tempTable.push(tempTableObject);
          setTableData(tempTable);
          setPoolData([]);
        }
      }
    }
  }, [check]);

  function selectCountry(value) {
    //update payer type and payer role based on country
    setPoolData([]);
    setCountry(value);
    let tempPayerRole = [];
    for (let i = 0; i < payerData.length; i++) {
      if (payerData[i]["country"] === value) {
        tempPayerRole.push(payerData[i]["payerRole"]);
      }
    }
    setPayerRole([...new Set(tempPayerRole)]);
    let tempPayerType = [];
    for (let i = 0; i < payerData.length; i++) {
      if (payerData[i]["country"] === value) {
        tempPayerType.push(payerData[i]["payerType"]);
      }
    }
    setPayerType([...new Set(tempPayerType)]);
    form.setFieldsValue({ payertype: undefined, payerrole: undefined });
  }

  function selectPayerType(value) {
    //update payer role based on payer type
    let tempPayerRole = [];
    for (let i = 0; i < payerData.length; i++) {
      if (
        payerData[i]["payerType"] === value &&
        payerData[i]["country"] === country
      ) {
        tempPayerRole.push(payerData[i]["payerRole"]);
      }
    }
    setPayerRole([...new Set(tempPayerRole)]);
    form.setFieldsValue({ payerrole: undefined });
  }

  function onFinish(values) {
    setFoundLessPayers(false);
    //check if any of te search values is undefined
    if (values["count"] === undefined) {
      message.error("Select count!");
    } else if (values["country"] === undefined) {
      message.error("Select Country!");
    } else if (values["payertype"] === undefined) {
      message.error("Select Payer Type!");
    } else if (values["payerrole"] === undefined) {
      message.error("Select Payer Expertise!");
    } else if (values["count"] > maxRespondentsPerCountry) {
      //check if the count of payers exceeds maxRespondentsPerCountry
      message.error(
        `Only ${maxRespondentsPerCountry} respondents allowed per country!`
      );
    } else {
      //check if the count of (payers already added + payers requested) exceeds maxRespondentsPerCountry
      let currentPayerCountByCountry = 0;
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i]["country"] === values["country"]) {
          currentPayerCountByCountry += tableData[i]["count"];
        }
      }
      if (SurveyStore.getIsChangingPayers()) {
        for (let i = 0; i < initialTableData.length; i++) {
          if (initialTableData[i]["country"] === values["country"]) {
            currentPayerCountByCountry += initialTableData[i]["count"];
          }
        }
      }
      if (
        currentPayerCountByCountry + values["count"] >
        maxRespondentsPerCountry
      ) {
        message.error(
          `Only ${maxRespondentsPerCountry} respondents allowed per country!`
        );
        return;
      }
      //fetch payer data for search values
      let tempPoolData = [];
      let j = 0;
      for (let i = 0; i < copyPayerData.length; i++) {
        if (
          copyPayerData[i]["country"] === values["country"] &&
          copyPayerData[i]["payerRole"] === values["payerrole"] &&
          copyPayerData[i]["payerType"] === values["payertype"]
        ) {
          tempPoolData.push(copyPayerData[i]);
        }
        if (tempPoolData.length === values["count"]) break;
      }
      if (tempPoolData.length < values["count"]) {
        setFoundLessPayers(true);
      }
      setPoolData(tempPoolData);
    }
  }

  useEffect(() => {
    if (renderPoolData.length !== 0)
      window.scrollTo(0, document.body.scrollHeight);
  }, [renderPoolData]);

  function clearForm() {
    //clear form values
    setPoolData([]);
    document.getElementById("searchForm").reset();
  }

  function addToPool() {
    //add data to table
    setCheck(check ? false : true);
    document.getElementById("searchForm").reset();
  }

  function close() {
    //remove searched data
    setPoolData([]);
  }

  function deleteRecordFromTable(record) {
    //function to delete record from selected respondents table
    setIsDeleted(true);
    setDataRecord(record);
    setCheck(check ? false : true);
  }

  function PublishSurvey() {
    //function to publish survey
    setIsLoading(true);
    let temp = [];

    //get quesIDs of custom questions
    let tempCustomQuesIDs = [];
    // console.log("storeData", storeData);
    for (let i = 0; i < storeData.length; i++) {
      if (storeData[i]["quesTitle"] === "Custom") {
        tempCustomQuesIDs.push(storeData[i]["quesID"]);
      }
    }

    for (let i = 0; i < tableData.length; i++) {
      temp.push({
        clientID: storeData[0]["clientID"],
        surveyID: storeData[0]["surveyID"],
        country: tableData[i]["country"],
        payerRole: tableData[i]["role"],
        payerType: tableData[i]["type"],
        payerCount: tableData[i]["count"],
        payerID: 0,
        payerName: "",
        payerEmail: "",
        surveyLink: "",
        exactMatch: "",
        floatSurvey: "Yes",
        addedAt: "0001-01-01T00:00:00",
        addedBy: "",
        customQuesIdList: tempCustomQuesIDs,
      });
    }

    // console.log("Data to send", temp);
    //API to publish the survey, provided the list of respondents
    let saveAsDraft = button === "Publish" ? false : true;
    if (!saveAsDraft) setIsPublished(true);
    else setIsPublished(false);
    let surveyQuestions = 0;
    if (
      storeData[0]["quesText"] !== "" &&
      storeData[0]["quesText"] !== undefined &&
      storeData[0]["quesText"] !== null
    )
      surveyQuestions = storeData.length - 2;
    request
      .post(
        APP_CONSTANTS.API_URL +
          "/PublishSurvey?SurveyId=" +
          storeData[0]["surveyID"] +
          "&saveAsDraft=" +
          saveAsDraft +
          "&surveyQuestions=" +
          surveyQuestions,
        temp
      )
      .then((response) => {
        // console.log("Response", response);
        if (response.data.status === 200) {
          {
            setIsLoading(false);
            setIsPublished(false);
            if (!saveAsDraft) {
              SurveyStore.increaseNavigationCounter();
              navigate("/publishsurvey");
            } else {
              message.success("Saved!");
            }
          }
        } else {
          message.error("Error!");
          setIsLoading(false);
          setIsPublished(false);
        }
      })
      .catch((err) => {
        message.error("Error!");
        // console.log(err);
        setIsPublished(false);
        setIsLoading(false);
      });
  }

  const showModalPublish = () => {
    setButton("Publish");
    setIsModalVisiblePublish(true);
  };
  const showModalCancel = () => {
    setIsModalVisibleCancel(true);
  };

  const handleOkPrevious = () => {
    //navigate to previous screen
    setIsModalVisiblePrevious(false);
    SurveyStore.setIsEdited(true);
    navigate("/reviewsurvey");
  };

  useEffect(() => {
    // if page is refreshed navigate to homepage
    if (SurveyStore.getNavigationCounter() === 0) {
      navigate("/");
    }
  });

  const handleOk = () => {
    setIsLoading(true);
    setIsModalVisibleCancel(false);
    const storeData = SurveyStore.returnData();
    SurveyStore.deleteStoreData();
    // if (!SurveyStore.getIsEdited()) {
    //   // delete from DB as well
    //   axios
    //     .delete(
    //       APP_CONSTANTS.API_URL +
    //         "/DeleteSurvey?ClientId=" +
    //         storeData[0]["clientID"] +
    //         "&SurveyId=" +
    //         storeData[0]["surveyID"]
    //     )
    //     .then((res) => {
    //       if (res["status"] === 200) {
    //         // console.log(
    //           "Deleted success >> Survey ID:",
    //           storeData[0]["surveyID"]
    //         );
    //       } else {
    //         // console.log("error deleting survey");
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((error) => {
    //       message.error("Error");
    //       // console.log(error);
    //       setIsLoading(false);
    //     })
    //     .then((res) => {
    //       setIsLoading(false);
    //       navigate("/currentsurveys");
    //     });
    // } else {
    setIsLoading(false);
    navigate("/currentsurveys");
  };

  function saveAsDraftHandler() {
    setButton("Save");
    setIsPublished(false);
    PublishSurvey();
  }

  const handleOkPublish = () => {
    if (tableData.length === 0) {
      message.error("No respondents added!");
      setIsModalVisiblePublish(false);
      return;
    }
    const currentStore = CurrentSurveyStore.getCurrentSurveyData();
    const surveyStore = SurveyStore.returnData();
    if (
      currentStore !== null &&
      currentStore !== undefined &&
      currentStore.length !== 0 &&
      surveyStore !== null &&
      surveyStore !== undefined &&
      surveyStore.length !== 0
    ) {
      if (currentStore[0]["creditsBalance"] < surveyStore.length) {
        setIsModalVisibleCreditsLow(true);
        return;
      }
    }

    PublishSurvey();
  };

  const showModalPrevious = () => {
    setIsModalVisiblePrevious(true);
  };

  const handleCancel = () => {
    setIsModalVisibleCancel(false);
    setIsModalVisiblePublish(false);
    setIsModalVisiblePrevious(false);
    setIsModalVisibleCreditsLow(false);
    setButton("Save");
  };
  function handleOkCreditsLow() {
    setIsLoading(true);
    setIsModalVisibleCreditsLow(false);
    setIsModalVisiblePublish(false);
    // navigate("/currentsurveys");
  }
  //define columns for respondent table
  const columnsInitial = [
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
    },
    {
      title: "",
      dataIndex: "type",
      key: "delete",
      align: "center",
      render: (text, record) => <div></div>,
      colSpan: 50,
    },
  ];

  const columns = [
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "",
      dataIndex: "type",
      key: "delete",
      align: "center",
      render: (text, record) => (
        <Button type="link" onClick={() => deleteRecordFromTable(record)}>
          <Tooltip title="Delete Record">
            <DeleteFilled style={{ fontSize: "1.2rem", color: "#27a6a4" }} />
          </Tooltip>
        </Button>
      ),
      colSpan: 50,
    },
  ];

  return (
    <div style={{ minHeight: "87.5vh" }}>
      {isLoading ? (
        <div>
          {isPublished ? (
            <div
              className="flex justify-center items-center"
              style={{ minHeight: "87.5vh" }}
            >
              <div>
                <div className="flex justify-center">
                  <ScaleLoader
                    color="teal"
                    height={60}
                    width={6}
                    radius={18}
                    margin={2}
                  />
                </div>
                <div>
                  <p className="text-lg font-semibold">
                    Your surveys are being sent out!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex justify-center items-center"
              style={{ minHeight: "87.5vh" }}
            >
              <ScaleLoader
                color="teal"
                height={60}
                width={6}
                radius={18}
                margin={2}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="pb-2">
          <Stepper current={3} />
          <div className="px-36 flex mt-4">
            <div>
              {/* <p className="text-xl font-bold mb-2">Respondent Selection</p> */}
              <p className="text-sm mb-0">
                {storeData[0]["surveyDescription"]}
              </p>
            </div>
          </div>
          <div style={{ minHeight: "54vh" }} className="mx-36 mt-6">
            <div className="flex">
              <div
                style={{ height: "24rem" }}
                id="searchcriteria"
                className="w-1/4 mr-6"
              >
                <p className="mb-1 font-bold">Search Criteria</p>
                <hr />
                <Form form={form} name="searchForm" onFinish={onFinish}>
                  <div className="text-red-500 text-xs">
                    * At most 3 respondents can be added per country in the EU
                    region. For US, at most 5 respondents can be added.
                  </div>
                  <div className="grid grid-cols-3 mt-2">
                    <div className="col-span-1">
                      <p className="mb-1">Count</p>
                      <Form.Item name="count">
                        <InputNumber min={0} max={maxRespondentsPerCountry} />
                      </Form.Item>
                    </div>
                    <div className="ml-8 col-span-2">
                      <p className="mb-1">Country</p>
                      <Form.Item name="country">
                        <Select onSelect={selectCountry}>
                          {countries.map((country) => {
                            return <Option value={country}>{country}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <p className="mb-1">Payer Type</p>
                    <Form.Item name="payertype">
                      <Select onSelect={selectPayerType}>
                        {payerType.map((type) => {
                          return <Option value={type}>{type}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className=" grid grid-cols-1">
                    <p className="mb-1">Payer Expertise</p>
                    <Form.Item name="payerrole">
                      <Select>
                        {payerRole.map((role) => {
                          return <Option value={role}>{role}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="flex ml-0 mt-2">
                    <Space size="large">
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="default" onClick={clearForm}>
                          Reset
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                </Form>
              </div>
              <div className="w-3/4">
                <div id="respondentcard">
                  <p className="mb-2 font-bold">Survey Respondent List</p>
                  <hr />
                  {tableData.length === 0 && initialTableData.length === 0 ? (
                    <div className="w-full text-center mt-4 mb-4 text-sm border-gray-300 border-2 h-36 bg-gray-50">
                      <div className="mt-4 mb-2">
                        <FileSearchOutlined
                          style={{ fontSize: "3rem", color: "#27a6a4" }}
                        />
                      </div>
                      <p>
                        Use the search to find and add participants to this
                        survey's respondent list.
                      </p>
                    </div>
                  ) : (
                    <div>
                      {initialTableData.length === 0 ? (
                        ""
                      ) : (
                        <div>
                          <p className="mb-1 text-sm font-semibold">
                            Previously Selected Respondents
                          </p>
                          <div style={{ cursor: "not-allowed" }}>
                            <Table
                              className="initialPayerTableRows"
                              dataSource={initialTableData}
                              columns={columnsInitial}
                              pagination={false}
                              rowClassName="initialPayerTableRows"
                            />
                          </div>
                          <p className="mt-6 mb-1 text-sm font-semibold">
                            Newly Added Respondents
                          </p>
                        </div>
                      )}

                      {tableData.length === 0 ? (
                        <div className="w-full text-center mt-4 mb-4 text-sm border-gray-300 border-2 h-36 bg-gray-50">
                          <div className="mt-4 mb-2">
                            <FileSearchOutlined
                              style={{ fontSize: "3rem", color: "#27a6a4" }}
                            />
                          </div>
                          <p>
                            Use the search to find and add participants to this
                            survey's respondent list.
                          </p>
                        </div>
                      ) : (
                        <Table
                          dataSource={tableData}
                          columns={columns}
                          pagination={false}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div>
                  {renderPoolData.length != 0 ? (
                    <div className="bg-green-50 mt-4 border-2 border-green-700">
                      <div>
                        {foundLessPayers ? (
                          <div className="pt-2 pl-4 pb-0 mb-0">
                            <p className="mb-0 font-bold text-red-500">
                              Found only {renderPoolData.length} matches!
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="pt-1 pl-4 flex">
                        <div className="w-3/4 flex">
                          <div>
                            <CheckCircleFilled
                              style={{ fontSize: "1.2rem", color: "green" }}
                            />
                          </div>
                          <div className="ml-4 pt-1">
                            Accept {renderPoolData.length}{" "}
                            <b>{renderPoolData[0]["country"]}</b>(Country),
                            <b>{renderPoolData[0]["payerType"]}</b>(Payer Type),
                            <b>{renderPoolData[0]["payerRole"]}</b>(Payer
                            Expertise) payer matches?
                          </div>
                        </div>
                        <div className="w-1/4 flex justify-end">
                          <div className="flex">
                            <div>
                              <Button type="link" onClick={addToPool}>
                                Add to Pool
                              </Button>
                            </div>
                            <div>
                              <Button
                                style={{ paddingTop: 0 }}
                                type="link"
                                onClick={close}
                              >
                                <CloseOutlined
                                  style={{ color: "darkslategray" }}
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="footerOne" className="mt-12">
            <Card size="small">
              <Space>
                <Button
                  type={tableData.length === 0 ? "default" : "primary"}
                  onClick={showModalPublish}
                  disabled={tableData.length === 0}
                >
                  Publish Survey
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    saveAsDraftHandler();
                  }}
                >
                  Save as Draft
                </Button>
                {SurveyStore.getIsChangingPayers() ? (
                  <Button disabled>Previous</Button>
                ) : (
                  <Button onClick={showModalPrevious}>Previous</Button>
                  // <Button
                  //   onClick={() => {

                  //   }}
                  // >
                  //   Previous
                  // </Button>
                )}

                <Button type="danger" onClick={showModalCancel}>
                  Cancel
                </Button>
              </Space>
            </Card>
          </div>
          <Modal
            title="Publish Survey"
            visible={isModalVisiblePublish}
            onOk={handleOkPublish}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to publish your survey?</p>
          </Modal>

          <Modal
            title="Cancel Survey"
            visible={isModalVisibleCancel}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            {/* {!SurveyStore.getIsEdited() ? (
              <p>Do you want to delete this survey and all its related data?</p>
            ) : (
              <p>Are you sure? Changes made won't be applied!</p>
            )} */}
            <p>Are you sure? Changes made won't be applied!</p>
          </Modal>
          <Modal
            title="Low Credits!"
            visible={isModalVisibleCreditsLow}
            // onOk={handleOkCreditsLow}
            // okText="Back to Survey Dashboard"
            onCancel={handleCancel}
            footer={[
              <a onClick={() => SurveyStore.deleteStoreData()} href="/">
                <Button
                  key="lowCreditOk"
                  type="primary"
                  onClick={handleOkCreditsLow}
                >
                  Back to Survey Dashboard
                </Button>
              </a>,
            ]}
          >
            <p className="text-red-500">
              You do not have enough credits to publish this survey!
            </p>
          </Modal>
          <Modal
            title="Go to Previous Screen"
            visible={isModalVisiblePrevious}
            onOk={handleOkPrevious}
            onCancel={handleCancel}
          >
            <p>Make sure you have saved you data before going back!</p>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default RespondentSelection;
