import {
  Table,
  Tag,
  Progress,
  Button,
  Tooltip,
  DatePicker,
  Modal,
  message,
} from "antd";
import "./CurrentSurveysTable.css";
import { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  ClockCircleFilled,
  EditFilled,
  AppstoreFilled,
  CopyFilled,
  DeleteFilled,
  FileAddOutlined,
  MailFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_CONSTANTS } from "../../Utils/Constant";
import CurrentSurveysHeader from "./CurrentSurveysHeader";
import { request } from "../../App";

const { RangePicker } = DatePicker;

//API to fetch all surveys created by the client
//returns an array of objects, containing each survey's data as an object
const getData = async (clientId) => {
  let ResultArray = [];
  const TeamName = JSON.parse(sessionStorage.getItem("appInfo"))["team_name"];
  await request
    .get(
      APP_CONSTANTS.API_URL +
        "/SurveyCardsInfo?ClientId=" +
        clientId +
        "&team_name=" +
        TeamName
    )
    .then((res) => {
      console.log(
        "teamname",
        JSON.parse(sessionStorage.getItem("appInfo"))["team_name"]
      );
      const result = res.data.result;
      // console.log("Result:", res);
      // console.log("Needed Result:", result);
      ResultArray = result;
    })
    .catch((err) => {
      message.error("Error!");
      // console.log({ err });
    });
  return ResultArray;
};

//API to fetch data for a particular survey
//returns an array of objects, containing each question as an object with survey's basic details.
const getQuestionData = async (clientId, surveyId) => {
  // console.log(surveyId);
  let ResultArray = [];
  await request
    .get(
      APP_CONSTANTS.API_URL +
        "/EachSurveyInfo?ClientId=" +
        clientId +
        "&SurveyId=" +
        surveyId
    )
    .then((res) => {
      // console.log(res);
      const result = res.data.result;
      ResultArray = result;
      // console.log("survey name", result[0]["surveyName"]);
      // console.log("Result: from api", result);
    })
    .catch((err) => {
      // console.log({ err });
    });
  return ResultArray;
};

function CurrentSurveysTable({ CurrentSurveyStore, SurveyStore }) {
  const [loadedData, setLoadedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [deleteClientID, setDeleteClientID] = useState();
  const [deleteSurveyID, setDeleteSurveyID] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportData, setReportData] = useState([]);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteSurvey(deleteClientID, deleteSurveyID);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onChange(value, dateString) {
    //modify datestring's 'to date' to be the 'next date value' (+1 day) instead of its 'current date value'
    if (dateString[0] === "") {
      setFilteredData(loadedData);
    } else {
      let date = new Date(dateString[1]);
      date.setDate(date.getDate() + 1);
      dateString[1] = date.toISOString().split("T")[0];
      // console.log("Date Range Selected: ", dateString);
      setDateRange(dateString);
    }
  }

  function onOk(value) {
    // console.log("onOk: ", value);
  }
  function navigateToCreateSurveyFields() {
    //navigate to create survey page
    SurveyStore.increaseNavigationCounter();
    navigate("/createsurvey");
    setIsLoading(false);
  }

  function navigateToCreateSurveyQuestions() {
    //navigate to Survey questions page
    SurveyStore.increaseNavigationCounter();
    navigate("/createsurveyquestions");
    setIsLoading(false);
  }

  function navigateToReviewSurvey() {
    //navigate to review survey page
    SurveyStore.increaseNavigationCounter();
    navigate("/reviewsurvey");
    setIsLoading(false);
  }

  function navigateToRespondentSelection() {
    //navigate to respondent selection page
    SurveyStore.increaseNavigationCounter();
    navigate("/respondentselection");
    setIsLoading(false);
  }

  useEffect(() => {
    if (
      JSON.parse(sessionStorage.getItem("appInfo")) === null ||
      JSON.parse(sessionStorage.getItem("appInfo")) === undefined
    ) {
      navigate("/");
      return;
    }
    if (
      JSON.parse(sessionStorage.getItem("appInfo"))["isPayer"] === true &&
      JSON.parse(sessionStorage.getItem("appInfo"))["isClient"] === false &&
      JSON.parse(sessionStorage.getItem("appInfo"))["isAdmin"] === false
    ) {
      setIsLoading(true);
      navigate("/payerstatus");
      return;
    }
    // get current surveys
    window.scrollTo(0, 0);
    SurveyStore.setClientId(
      JSON.parse(sessionStorage.getItem("appInfo"))["clientId"]
    );
    if (
      CurrentSurveyStore.getCurrentSurveyData() === undefined ||
      CurrentSurveyStore.getCurrentSurveyData() === null ||
      CurrentSurveyStore.getCurrentSurveyData().length === 0
    ) {
      // console.log("API CALLED", SurveyStore.getClientId());
      getData(SurveyStore.getClientId())
        .then((output) => {
          let appInfo = JSON.parse(sessionStorage.getItem("appInfo"));
          // console.log(appInfo);
          appInfo["creditsBalance"] = output[0]["creditsBalance"];
          sessionStorage.setItem("appInfo", JSON.stringify(appInfo));
          if (output[0]["surveyId"] !== 0 && output[0]["surveyName"] !== null) {
            setLoadedData(output);
            setFilteredData(output);
            CurrentSurveyStore.addCurrentSurveyData(output);
          }
        })
        .then(() => setIsLoading(false));
      // setIsLoading(false);
    } else {
      setLoadedData(CurrentSurveyStore.getCurrentSurveyData());
      setFilteredData(CurrentSurveyStore.getCurrentSurveyData());
      setIsLoading(false);
    }
  }, []);

  function copySurvey(data) {
    //functions which copies a survey with a blank name, surveyId, payerId, documents_list
    setIsLoading(true);
    setIsLoadingEdit(true);
    // console.log("Data to Copy:", data);
    //get data for the survey which is to be edited
    getQuestionData(SurveyStore.getClientId(), data["surveyId"]).then(
      (output) => {
        let copyData = output;
        //update this survey's name, surveyId, payerId, documents_list to create a new survey
        copyData.forEach((item) => {
          item["surveyName"] = "";
          item["surveyID"] = 0;
          item["payerID"] = 0;
          item["docs_link"] = [];
          item["lastSavedStep"] = 0;
          item["is_highlight"] = false;
        });
        SurveyStore.deleteStoreData();
        // console.log("Copy Data after preprocessing", copyData);
        SurveyStore.addSurveyFieldsData(copyData[0]);
        SurveyStore.addQuestionData(copyData);
        navigateToCreateSurveyFields();
      }
    );
  }

  function editSurvey(data) {
    //function to edit a survey
    setIsLoading(true);
    setIsLoadingEdit(true);
    SurveyStore.setIsEdited(true);
    // console.log("Data to Edit:", data);
    // sessionStorage.setItem(
    //   "surveyId",
    //   JSON.stringify({
    //     surveyId: data["surveyId"],
    //   })
    // );
    //get data for the survey which is to be edited
    getQuestionData(SurveyStore.getClientId(), data["surveyId"]).then(
      (output) => {
        // console.log("output", output);
        let lastSavedStep = output[0]["lastSavedStep"];
        SurveyStore.addSurveyFieldsData(output[0]);
        SurveyStore.addQuestionData(output);
        if (lastSavedStep === 2) navigateToCreateSurveyQuestions();
        else if (lastSavedStep === 3) navigateToReviewSurvey();
        else if (lastSavedStep === 4) navigateToRespondentSelection();
        else navigateToCreateSurveyFields();
      }
    );
  }

  const sendReminderEmail = async (surveyId) => {
    //API to send a reminder mail to elgible payers to complete the survey
    await request
      .post(APP_CONSTANTS.API_URL + "/SendReminderMail?SurveyId=" + surveyId)
      .then((res) => {
        const result = res;
        // console.log("Result:", result);
        if (res.data.status === 200) {
          message.success("Reminders sent successfully!");
        }
      })
      .catch((err) => {
        message.error("Error!");
        // console.log({ err });
      });
  };

  function sendReminder(data) {
    message.loading("Sending out Reminders! Please wait", [4]);
    // console.log("Sending Email reminders for surveyID:", data["surveyId"]);
    sendReminderEmail(data["surveyId"]);
  }

  function selectNewRespondents(data) {
    //function called when user wants to add payers
    // console.log("Editing respondents for ", data["surveyId"]);
    setIsLoading(true);
    setIsLoadingEdit(true);
    SurveyStore.setIsChangingPayers(true);
    //get data for the survey which is to be edited
    getQuestionData(SurveyStore.getClientId(), data["surveyId"]).then(
      (output) => {
        // console.log("output", output);
        SurveyStore.addSurveyFieldsData(output[0]);
        SurveyStore.addQuestionData(output);
        navigateToRespondentSelection();
      }
    );
  }

  function viewSurveySummary(data) {
    //function which takes user to survey summary page, where survey's stats and responses are shown
    setIsLoading(true);
    setIsLoadingSummary(true);
    // sessionStorage.setItem(
    //   "surveyId",
    //   JSON.stringify({
    //     surveyId: data["surveyId"],
    //   })
    // );
    // console.log("Summary of", data);
    //get data for the survey which summary is to be viewed
    getQuestionData(SurveyStore.getClientId(), data["surveyId"])
      .then((output) => {
        SurveyStore.addSurveyFieldsData(output[0]);
        SurveyStore.addQuestionData(output);
      })
      .catch((err) => {
        message.error("Error");
        // console.log(err);
        setIsLoading(false);
        setIsLoadingSummary(true);
      })
      .then(() => {
        setIsLoading(false);
        setIsLoadingSummary(true);
        SurveyStore.increaseNavigationCounter();
        navigate("/surveysummary");
      });
  }

  useEffect(() => {
    //update current surveys based on date Filter
    let tempfilteredData = [];
    if (loadedData) {
      for (let i = 0; i < loadedData.length; i++) {
        if (
          loadedData[i]["deadline"] <= dateRange[1] + 1 &&
          loadedData[i]["deadline"] >= dateRange[0]
        ) {
          tempfilteredData.push(loadedData[i]);
        }
      }
      setFilteredData([...tempfilteredData]);
    }
    if (dateRange[1] == "") {
      setFilteredData(loadedData);
    }
  }, [dateRange]);

  function deleteSurvey(clientId, surveyId) {
    //function to delete a survey
    // console.log("surveyId", surveyId, "deleting");
    message.loading("Deleting", [1]);
    setIsModalVisible(false);
    //API to delete a survey
    request
      .delete(
        APP_CONSTANTS.API_URL +
          "/DeleteSurvey?ClientId=" +
          clientId +
          "&SurveyId=" +
          surveyId
      )
      .then((res) => {
        if (res.data.status === 200) {
          // if deleted succesfully, remove it from the stores as well
          // console.log("Deleted successfully", surveyId);
          CurrentSurveyStore.deleteSurvey(surveyId);
          setLoadedData(CurrentSurveyStore.getCurrentSurveyData());
          setFilteredData(CurrentSurveyStore.getCurrentSurveyData());
          message.success("Deleted!");
        } else {
          message.error("Error!");
        }
      })
      .catch((err) => {
        message.error("Error!");
        // console.log(err);
      });
  }

  function CreateNewSurveyHandler() {
    //function to create a new survey
    if (SurveyStore !== undefined) SurveyStore.deleteStoreData();
    debugger;
    SurveyStore.increaseNavigationCounter();
    navigate("/createsurvey");
  }

  // get filters for Survey Name header
  let filtersSurveyName = [];
  if (loadedData) {
    for (let i = 0; i < loadedData.length; i++) {
      filtersSurveyName.push({
        text: loadedData[i].surveyName,
        value: loadedData[i].surveyName,
      });
    }
  }

  function workingHoursBetweenDates(endDate) {
    let startDate = new Date(); //current date time
    const offset = startDate.getTimezoneOffset();
    startDate.setMinutes(startDate.getMinutes() + offset);
    var minutesWorked = 0;

    if (endDate < startDate) {
      message.error("Error!");
      return 0;
    }
    var current = startDate;
    // Define working hours range
    var workHoursStart = 0;
    var workHoursEnd = 24;
    var includeWeekends = true;

    // Loop while currentDate is less than end Date (by minutes)
    while (current <= endDate) {
      // Is the current time within a work day and if it occurs on a weekend or not
      if (
        current.getHours() >= workHoursStart &&
        current.getHours() < workHoursEnd &&
        (includeWeekends
          ? current.getDay() !== 0 && current.getDay() !== 6
          : true)
      ) {
        minutesWorked++;
      }

      // Increment current time
      current.setTime(current.getTime() + 1000 * 60);
    }

    // Return the number of hours
    return minutesWorked / 60;
  }

  //define columns for surveys table
  const columns = [
    {
      title: "Survey Name",
      dataIndex: "surveyName",
      key: "surveyName",
      render: (text, record) => (
        <>
          {record["surveyStatus"].map((stat, i) => {
            //check whether to edit survey or to view its summary according to survey status
            if (stat === "Unpublished") {
              return (
                <p
                  key={i}
                  style={{ cursor: "pointer", wordBreak: "break-all" }}
                  onClick={() => editSurvey(record)}
                  className="mb-0"
                >
                  {text}
                </p>
              );
            } else {
              return (
                <p
                  key={i}
                  style={{ cursor: "pointer", wordBreak: "break-all" }}
                  onClick={() => viewSurveySummary(record)}
                  className="mb-0"
                >
                  {text}
                </p>
              );
            }
          })}
        </>
      ),
      sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
      filters: filtersSurveyName,
      filterSearch: true,
      onFilter: (value, record) => record["surveyName"].indexOf(value) === 0,
    },
    {
      title: "Status",
      key: "surveyStatus",
      dataIndex: "surveyStatus",
      align: "center",
      render: (surveyStatus) => (
        <>
          {surveyStatus.map((stat, i) => {
            // map each surveyStatus to corresponding color
            let color = "default";
            if (stat === "Completed") {
              color = "green";
            } else if (stat === "Published") {
              color = "blue";
            }
            return (
              <Tag key={i} color={color} style={{ fontSize: "0.8rem" }}>
                {stat}
              </Tag>
            );
          })}
        </>
      ),
      sorter: (a, b) => a.surveyStatus[0].localeCompare(b.surveyStatus[0]),
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Unpublished",
          value: "Unpublished",
        },
        {
          text: "Published",
          value: "Published",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record["surveyStatus"].indexOf(value) === 0,
    },
    {
      title: "Accepted",
      dataIndex: [
        "totalAccepted",
        "totalApproached",
        "totalRejected",
        "surveyStatus",
      ],
      key: "acceptance",
      align: "center",
      render: (text, record) => (
        <>
          {record["surveyStatus"].map((stat, i) => {
            // get accepted and rejected values according to survey status
            if (stat === "Completed") {
              return (
                <Tooltip
                  key={i}
                  title={
                    "Accepted: " +
                    record["totalAccepted"] +
                    " (" +
                    (
                      (record["totalAccepted"] / record["totalApproached"]) *
                      100
                    ).toFixed(0) +
                    "%)" +
                    " | Declined: " +
                    record["totalRejected"] +
                    " (" +
                    (
                      (record["totalRejected"] / record["totalApproached"]) *
                      100
                    ).toFixed(0) +
                    "%)"
                  }
                >
                  <Progress
                    //percent denotes the overall percentage
                    percent={parseInt(
                      ((record["totalAccepted"] + record["totalRejected"]) /
                        record["totalApproached"]) *
                        100
                    )}
                    strokeColor="#fc001f"
                    //success denotes the payers who have accepted the survey
                    success={{
                      percent:
                        (record["totalAccepted"] / record["totalApproached"]) *
                        100,
                      strokeColor: "#00AA67",
                    }}
                    trailColor="#DEDCDE"
                    format={(format) =>
                      `${record["totalAccepted"]}/${record["totalApproached"]}`
                    }
                  />
                </Tooltip>
              );
            } else if (stat === "Published") {
              return (
                <Tooltip
                  key={i}
                  title={
                    "Accepted: " +
                    record["totalAccepted"] +
                    " (" +
                    (
                      (record["totalAccepted"] / record["totalApproached"]) *
                      100
                    ).toFixed(0) +
                    "%)" +
                    " | Declined: " +
                    record["totalRejected"] +
                    " (" +
                    (
                      (record["totalRejected"] / record["totalApproached"]) *
                      100
                    ).toFixed(0) +
                    "%)"
                  }
                >
                  <Progress
                    percent={parseInt(
                      ((record["totalAccepted"] + record["totalRejected"]) /
                        record["totalApproached"]) *
                        100
                    )}
                    strokeColor="#fc001f"
                    success={{
                      percent:
                        (record["totalAccepted"] / record["totalApproached"]) *
                        100,
                      strokeColor: "#7E92E9",
                    }}
                    trailColor="#DEDCDE"
                    format={(format) =>
                      `${record["totalAccepted"]}/${record["totalApproached"]}`
                    }
                  />
                </Tooltip>
              );
            } else if (stat === "Unpublished") {
              return (
                <Tooltip key={i} className="mr-8" title="Not Published">
                  <Progress
                    percent={0}
                    trailColor="#DEDCDE"
                    format={(format) => `N/A`}
                  />
                </Tooltip>
              );
            }
          })}
        </>
      ),
      sorter: (a, b) => a.totalAccepted - b.totalAccepted,
    },
    {
      title: "Completed",
      dataIndex: ["completedSurvey", "totalApproached"],
      key: "completedSurvey",
      align: "center",
      render: (text, record) => (
        <>
          {record["surveyStatus"].map((stat, i) => {
            // get compeleted values according to survey status
            if (stat === "Completed") {
              return (
                <Tooltip
                  key={i}
                  title={
                    "Completed: " +
                    record["completedSurvey"] +
                    " (" +
                    (
                      (record["completedSurvey"] / (record["totalApproached"] - record["totalRejected"])) *
                      100
                    ).toFixed(0) +
                    "%)"
                  }
                >
                  <Progress
                    //percent denotes the overall percentage
                    className="completeProgress"
                    percent={parseInt(
                      ((record["completedSurvey"] ) /
                        (record["totalApproached"] - record["totalRejected"])) *
                        100
                    )}
                    //success denotes the payers who have completed the survey
                    success={{
                      percent:
                        (record["completedSurvey"] /
                          (record["totalApproached"] - record["totalRejected"])) *
                        100,
                      strokeColor: "#00AA67",
                    }}
                    strokeColor="#DEDCDE"
                    trailColor="#DEDCDE"
                    format={(format) =>
                      `${record["completedSurvey"]}/${(record["totalApproached"] - record["totalRejected"])}`
                    }
                  />
                </Tooltip>
              );
            } else if (stat === "Published") {
              return (
                <Tooltip
                  key={i}
                  title={
                    "Completed: " +
                    record["completedSurvey"] +
                    " (" +
                    (
                      (record["completedSurvey"] / (record["totalApproached"] - record["totalRejected"])) *
                      100
                    ).toFixed(0) +
                    "%)"
                  }
                >
                  <Progress
                    className="completeProgress"
                    percent={parseInt(
                      ((record["completedSurvey"]) /
                        (record["totalApproached"] - record["totalRejected"])) *
                        100
                    )}
                    success={{
                      percent:
                        (record["completedSurvey"] /
                          (record["totalApproached"] - record["totalRejected"])) *
                        100,
                      strokeColor: "#7E92E9",
                    }}
                    strokeColor="#DEDCDE"
                    trailColor="#DEDCDE"
                    format={(format) =>
                      `${record["completedSurvey"]}/${(record["totalApproached"] - record["totalRejected"])}`
                    }
                  />
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={i} title="Not Published">
                  <Progress
                    percent={0}
                    trailColor="#DEDCDE"
                    format={(format) => `N/A`}
                  />
                </Tooltip>
              );
            }
          })}
        </>
      ),
      sorter: (a, b) => a.completedSurvey - b.completedSurvey,
    },
    {
      title: "Due Date",
      key: "deadline",
      dataIndex: "deadline",
      align: "center",
      render: (text, record) => (
        <>
          {record["surveyStatus"].map((stat, i) => {
            // displaye deadline according to surveyStatus
            if (stat === "Completed") {
              return (
                <div key={i}>
                  <ClockCircleFilled
                    style={{
                      marginRight: "1rem",

                      fontSize: "12px",
                    }}
                  />
                  {record["deadline"].split("T")[0]}
                  <div className="ml-7 text-green-700">
                    <b className="text-xs">Completed</b>
                  </div>
                </div>
              );
            } else if (stat === "Published") {
              return (
                <div key={i}>
                  <ClockCircleFilled
                    style={{
                      marginRight: "1rem",

                      fontSize: "12px",
                    }}
                  />
                  {record["deadline"].split("T")[0]}
                  <div className="ml-7 text-blue-700">
                    <b className="text-xs">
                      {Math.round(
                        workingHoursBetweenDates(new Date(record["deadline"]))
                      )}{" "}
                      Hours Left
                    </b>
                  </div>
                </div>
              );
            } else if (stat === "Unpublished") {
              return (
                <div key={i} style={{ height: "2.75rem" }}>
                  <ClockCircleFilled
                    style={{
                      marginRight: "5.5rem",

                      fontSize: "12px",
                    }}
                  />
                  <div className="ml-20 pl-1">
                    <hr
                      style={{
                        width: "1rem",
                        height: "0.1rem",
                        backgroundColor: "gray",
                      }}
                    />{" "}
                  </div>
                </div>
              );
            }
          })}
        </>
      ),
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
    },
    {
      title: "Actions",
      width: 250,
      key: "actions",
      dataIndex: ["surveyStatus", "surveyId"],
      align: "center",
      render: (text, record) => (
        <>
          {record["surveyStatus"].map((stat, i) => {
            // define actions according to surveyStatus
            if (stat === "Published") {
              return (
                <div
                  key={i}
                  style={{ height: "2.5rem" }}
                  className="pl-16 justify-end flex"
                >
                  <div className="">
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => viewSurveySummary(record)}
                    >
                      <Tooltip title="View Summary">
                        <AppstoreFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => selectNewRespondents(record)}
                    >
                      <Tooltip title="Edit Respondents">
                        <EditFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => copySurvey(record)}
                    >
                      <Tooltip title="Copy Survey">
                        <CopyFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                  <div>
                    {/* <Button
                      className="linkButton"
                      type="link"
                      onClick={() => sendReminder(record)}
                    >
                      <Tooltip title="Send Reminder to Payers">
                        <MailFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button> */}
                  </div>
                </div>
              );
            } else if (stat === "Unpublished") {
              return (
                <div
                  key={i}
                  style={{ height: "2.5rem" }}
                  className="pl-16 justify-end flex"
                >
                  <div>
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => editSurvey(record)}
                    >
                      <Tooltip title="Edit Survey">
                        <EditFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => {
                        setIsModalVisible(true);
                        setDeleteSurveyID(record["surveyId"]);
                        setDeleteClientID(record["createdBy"]);
                        // deleteSurvey(1, record["surveyId"])
                      }}
                    >
                      <Tooltip title="Delete Survey">
                        <DeleteFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                </div>
              );
            } else if (stat === "Completed") {
              return (
                <div
                  key={i}
                  style={{ height: "2.5rem" }}
                  className="pl-16 justify-end flex"
                >
                  <div className="">
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => viewSurveySummary(record)}
                    >
                      <Tooltip title="View Summary">
                        <AppstoreFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="linkButton"
                      type="link"
                      onClick={() => copySurvey(record)}
                    >
                      <Tooltip title="Copy Survey">
                        <CopyFilled
                          style={{ fontSize: "1.2rem", color: "black" }}
                        />
                      </Tooltip>
                    </Button>
                  </div>
                </div>
              );
            }
          })}
        </>
      ),
    },
  ];

  return (
    <div style={{ minHeight: "87.5vh" }} className="h-full">
      {isLoading ? (
        isLoadingSummary ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "87.5vh" }}
          >
            <div>
              <div className="flex justify-center items-center">
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
              <p className="text-black font-semibold text-xl mt-4">
                Creating dashboard, please wait...
              </p>
            </div>
          </div>
        ) : isLoadingEdit ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "87.5vh" }}
          >
            <div>
              <div className="flex justify-center items-center">
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
              <p className="text-black font-semibold text-xl mt-4">
                Fetching survey details, please wait...
              </p>
            </div>
          </div>
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ height: "87.5vh" }}
          >
            <div>
              <div className="flex justify-center items-center">
                <ScaleLoader
                  color="teal"
                  height={60}
                  width={6}
                  radius={18}
                  margin={2}
                />
              </div>
              <p className="text-black font-semibold text-xl mt-4">
                Organising surveys, please wait...
              </p>
            </div>
          </div>
        )
      ) : (
        <div style={{ minHeight: "86.8vh" }}>
          {loadedData === null || loadedData.length === 0 ? (
            <div className="h-full">
              <CurrentSurveysHeader
                CurrentSurveyStore={CurrentSurveyStore}
                SurveyStore={SurveyStore}
              />
              <div className="flex px-4">
                <div className="flex items-center w-1/4">
                  <Button onClick={CreateNewSurveyHandler} type="primary">
                    Create New Survey
                  </Button>
                </div>
                <div className="flex justify-end w-3/4">
                  <div className="my-2">
                    <RangePicker
                      format="YYYY-MM-DD"
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </div>
                </div>
              </div>
              <div className=" px-4 mx-4 text-black text-center mt-4 pt-24 text-2xl border-gray-300 border-2 h-96">
                <div className="mt-4">
                  <FileAddOutlined
                    style={{ fontSize: "5rem", color: "#27a6a4" }}
                  />
                </div>
                <br />
                No Surveys Created!
              </div>
            </div>
          ) : (
            <div style={{ minHeight: "87.2vh" }}>
              <CurrentSurveysHeader
                SurveyStore={SurveyStore}
                CurrentSurveyStore={CurrentSurveyStore}
              />

              <div className="flex px-4">
                <div className="flex items-center w-1/4">
                  <Button onClick={CreateNewSurveyHandler} type="primary">
                    Create New Survey
                  </Button>
                </div>
                <div className="flex justify-end w-3/4 ">
                  <div className="my-2">
                    <RangePicker
                      format="YYYY-MM-DD"
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </div>
                </div>
              </div>
              {dateRange.length === 0 ? (
                <div className="px-4">
                  <Table
                    className="dashboardTable"
                    columns={columns}
                    dataSource={loadedData}
                    rowClassName={() => "roundedRows"}
                  />
                </div>
              ) : (
                <div className="px-4">
                  <Table
                    columns={columns}
                    rowClassName={() => "roundedRows"}
                    dataSource={filteredData}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <Modal
        title="Delete Survey"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete this survey?</p>
      </Modal>
    </div>
  );
}

export default CurrentSurveysTable;
